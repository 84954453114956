var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.selectTextAreaData.length
    ? _c(
        "div",
        {
          staticClass: "drag-content-list",
          style:
            "z-index:" +
            _vm.position.zIndex +
            ";top:" +
            (_vm.position.mouseY - 20) +
            "px" +
            (_vm.position.mouseX
              ? `;left:${_vm.position.mouseX}px`
              : ";left:194px"),
          on: {
            mousemove: function ($event) {
              return _vm.changeIscheckText(true)
            },
            mouseout: function ($event) {
              return _vm.changeIscheckText(false)
            },
            mousedown: (e) => {
              _vm.mousedown(e, _vm.position)
            },
          },
        },
        [
          (((_vm.taskDetail.stageName == "翻译" ||
            _vm.taskDetail.stageName == "校对" ||
            _vm.taskDetail.stageName == "终审") &&
            _vm.changeAll) ||
            _vm.glossaryTags.filter(
              (w) =>
                (_vm.item.originalText &&
                  (_vm.item.originalText.replaceAll("\n", "").indexOf(w.value) >
                    -1 ||
                    w.value.indexOf(
                      _vm.item.originalText.replaceAll("\n", "")
                    ) > -1)) ||
                (_vm.item.showText &&
                  (_vm.item.showText.replaceAll("\n", "").indexOf(w.target) >
                    -1 ||
                    w.target.indexOf(_vm.item.showText.replaceAll("\n", "")) >
                      -1))
            ).length ||
            _vm.terms.filter(
              (w) =>
                (_vm.item.originalText &&
                  _vm.item.originalText.length > 1 &&
                  w.valueX &&
                  (_vm.item.originalText
                    .replaceAll("\n", "")
                    .indexOf(w.valueX) > -1 ||
                    w.valueX.indexOf(
                      _vm.item.originalText.replaceAll("\n", "")
                    ) > -1)) ||
                (_vm.item.originalText &&
                  _vm.item.originalText.length > 1 &&
                  w.valueM &&
                  (_vm.item.originalText
                    .replaceAll("\n", "")
                    .indexOf(w.valueM) > -1 ||
                    w.valueM.indexOf(
                      _vm.item.originalText.replaceAll("\n", "")
                    ) > -1)) ||
                (_vm.item.showText &&
                  _vm.item.showText.length > 1 &&
                  w.targetX &&
                  (_vm.item.showText.replaceAll("\n", "").indexOf(w.targetX) >
                    -1 ||
                    w.targetX.indexOf(_vm.item.showText.replaceAll("\n", "")) >
                      -1)) ||
                (_vm.item.showText &&
                  _vm.item.showText.length > 1 &&
                  w.targetM &&
                  (_vm.item.showText.replaceAll("\n", "").indexOf(w.targetM) >
                    -1 ||
                    w.targetM.indexOf(_vm.item.showText.replaceAll("\n", "")) >
                      -1)) ||
                (_vm.item.showText &&
                  _vm.item.showText.length > 1 &&
                  w.target &&
                  (_vm.item.showText.replaceAll("\n", "").indexOf(w.target) >
                    -1 ||
                    w.target.indexOf(_vm.item.showText.replaceAll("\n", "")) >
                      -1)) ||
                (_vm.item.originalText &&
                  _vm.item.originalText.length > 1 &&
                  w.value &&
                  (_vm.item.originalText.replaceAll("\n", "").indexOf(w.value) >
                    -1 ||
                    w.value.indexOf(
                      _vm.item.originalText.replaceAll("\n", "")
                    ) > -1))
            ).length ||
            _vm.termBacks.filter(
              (w) =>
                (_vm.item.originalText &&
                  _vm.item.originalText.length > 1 &&
                  (_vm.item.originalText.replaceAll("\n", "").indexOf(w.value) >
                    -1 ||
                    w.value.indexOf(
                      _vm.item.originalText.replaceAll("\n", "")
                    ) > -1)) ||
                (_vm.item.showText &&
                  _vm.item.showText.length > 1 &&
                  (_vm.item.showText.replaceAll("\n", "").indexOf(w.target) >
                    -1 ||
                    w.target.indexOf(_vm.item.showText.replaceAll("\n", "")) >
                      -1))
            ).length ||
            _vm.termNotes.filter(
              (w) =>
                w.target &&
                (_vm.item.showText.indexOf(w.value) > -1 ||
                  _vm.item.showText.indexOf(w.target) > -1)
            ).length ||
            _vm.yahooOrKdxList.length ||
            _vm.blacklist.some(
              (words) => _vm.getBlackWordsTwo(_vm.item, words).length
            )) &&
          _vm.item
            ? _c(
                "div",
                {
                  staticClass: "ocr-show-item-action",
                  style: _vm.getCardStyle(),
                  on: { mouseenter: (e) => _vm.leftOut(e, _vm.item) },
                },
                [
                  (_vm.taskDetail.stageName == "翻译" ||
                    _vm.taskDetail.stageName == "校对") &&
                  _vm.changeAll
                    ? _c("div", { staticClass: "ocr-show-item-action-top" }, [
                        _c(
                          "div",
                          { staticClass: "ocr-show-item-action-title" },
                          [_vm._v("【校对】对【翻译】软硬伤标记")]
                        ),
                        _c(
                          "div",
                          { staticClass: "ocr-show-item-action-radio" },
                          [
                            _c(
                              "span",
                              {
                                class: !_vm.selectErrorType
                                  ? "error-span"
                                  : "no-error-span",
                                style:
                                  _vm.taskDetail.stageName == "翻译"
                                    ? "cursor: no-drop;"
                                    : "",
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    _vm.taskDetail.stageName == "翻译"
                                      ? () => {}
                                      : _vm.changeInjuryType(0)
                                  },
                                },
                              },
                              [
                                _vm._v("无"),
                                _vm.selectErrorTypes.some((s) => s == 0)
                                  ? _c("a-icon", {
                                      attrs: {
                                        type: "check-circle",
                                        theme: "filled",
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "span",
                              {
                                class:
                                  _vm.selectErrorType == 3
                                    ? "error-span"
                                    : "no-error-span",
                                style:
                                  _vm.taskDetail.stageName == "翻译"
                                    ? "color: red; margin-left: 3px;cursor: no-drop;"
                                    : "color: red; margin-left: 3px",
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    _vm.taskDetail.stageName == "翻译"
                                      ? () => {}
                                      : _vm.changeInjuryType(3)
                                  },
                                },
                              },
                              [
                                _vm._v("错"),
                                _vm.selectErrorTypes.some((s) => s == 3)
                                  ? _c("a-icon", {
                                      attrs: {
                                        type: "check-circle",
                                        theme: "filled",
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "span",
                              {
                                class:
                                  _vm.selectErrorType == 2
                                    ? "error-span"
                                    : "no-error-span",
                                staticStyle: {
                                  color: "red",
                                  "margin-left": "3px",
                                },
                                style:
                                  _vm.taskDetail.stageName == "翻译"
                                    ? "color: red; margin-left: 3px;cursor: no-drop;"
                                    : "color: red; margin-left: 3px",
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    _vm.taskDetail.stageName == "翻译"
                                      ? () => {}
                                      : _vm.changeInjuryType(2)
                                  },
                                },
                              },
                              [
                                _vm._v("硬"),
                                _vm.selectErrorTypes.some((s) => s == 2)
                                  ? _c("a-icon", {
                                      attrs: {
                                        type: "check-circle",
                                        theme: "filled",
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "span",
                              {
                                class:
                                  _vm.selectErrorType == 1
                                    ? "error-span"
                                    : "no-error-span",
                                style:
                                  _vm.taskDetail.stageName == "翻译"
                                    ? "color: red; margin-left: 3px;cursor: no-drop;"
                                    : "color: red; margin-left: 3px",
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    _vm.taskDetail.stageName == "翻译"
                                      ? () => {}
                                      : _vm.changeInjuryType(1)
                                  },
                                },
                              },
                              [
                                _vm._v("软"),
                                _vm.selectErrorTypes.some((s) => s == 1)
                                  ? _c("a-icon", {
                                      attrs: {
                                        type: "check-circle",
                                        theme: "filled",
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "span",
                              {
                                class:
                                  _vm.selectErrorType == 4
                                    ? "error-span"
                                    : "no-error-span",
                                style:
                                  _vm.taskDetail.stageName == "翻译"
                                    ? "color: orange; margin-left: 3px;cursor: no-drop;"
                                    : "color: orange; margin-left: 3px",
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    _vm.taskDetail.stageName == "翻译"
                                      ? () => {}
                                      : _vm.changeInjuryType(4)
                                  },
                                },
                              },
                              [
                                _vm._v("驳"),
                                _vm.selectErrorTypes.some((s) => s == 4)
                                  ? _c("a-icon", {
                                      attrs: {
                                        type: "check-circle",
                                        theme: "filled",
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "ocr-show-item-action-input",
                            on: {
                              mousemove: function ($event) {
                                _vm.isDelete = true
                              },
                              mouseout: function ($event) {
                                _vm.isDelete = false
                              },
                            },
                          },
                          [
                            _c("div", [
                              !_vm.selectErrorType
                                ? _c(
                                    "div",
                                    { staticStyle: { "padding-left": "10px" } },
                                    _vm._l(
                                      _vm.topFeedbacks,
                                      function (feedbackItem) {
                                        return _c(
                                          "div",
                                          { key: feedbackItem.id },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "revisition-content",
                                                staticStyle: {
                                                  display: "flex",
                                                  "align-items": "center",
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      "margin-right": "5px",
                                                    },
                                                  },
                                                  [
                                                    _c("img", {
                                                      attrs: {
                                                        src: feedbackItem.avatar,
                                                        alt: "",
                                                      },
                                                    }),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: { flex: "1" },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "revisition-content-error",
                                                        staticStyle: {
                                                          height: "18px",
                                                          "font-size": "12px",
                                                        },
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              feedbackItem.username
                                                            )
                                                          ),
                                                        ]),
                                                        _c(
                                                          "span",
                                                          {
                                                            class:
                                                              "action-span",
                                                          },
                                                          [
                                                            (_vm.checkPermission(
                                                              "fk:edit"
                                                            ) ||
                                                              _vm.checkPermission(
                                                                "fk:dele"
                                                              ) ||
                                                              feedbackItem.userId ==
                                                                _vm.userInfo
                                                                  .id) &&
                                                            _vm.changeAll
                                                              ? _c(
                                                                  "a-dropdown",
                                                                  {
                                                                    attrs: {
                                                                      trigger: [
                                                                        "click",
                                                                      ],
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "a-icon",
                                                                      {
                                                                        attrs: {
                                                                          type: "more",
                                                                        },
                                                                      }
                                                                    ),
                                                                    _c(
                                                                      "a-menu",
                                                                      {
                                                                        attrs: {
                                                                          slot: "overlay",
                                                                        },
                                                                        slot: "overlay",
                                                                      },
                                                                      [
                                                                        feedbackItem.userId ==
                                                                          _vm
                                                                            .userInfo
                                                                            .id ||
                                                                        _vm.checkPermission(
                                                                          "fk:edit"
                                                                        )
                                                                          ? _c(
                                                                              "a-menu-item",
                                                                              {
                                                                                key: "0",
                                                                                on: {
                                                                                  mousemove:
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      _vm.isDelete = true
                                                                                    },
                                                                                  mouseout:
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      _vm.isDelete = false
                                                                                    },
                                                                                  click:
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.editLocalContent(
                                                                                        feedbackItem
                                                                                      )
                                                                                    },
                                                                                },
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "\n                            编辑\n                          "
                                                                                ),
                                                                              ]
                                                                            )
                                                                          : _vm._e(),
                                                                        feedbackItem.userId ==
                                                                          _vm
                                                                            .userInfo
                                                                            .id ||
                                                                        _vm.checkPermission(
                                                                          "fk:dele"
                                                                        )
                                                                          ? _c(
                                                                              "a-menu-item",
                                                                              {
                                                                                key: "1",
                                                                                on: {
                                                                                  mousemove:
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      _vm.isDelete = true
                                                                                    },
                                                                                  mouseout:
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      _vm.isDelete = false
                                                                                    },
                                                                                  click:
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.deletFeedback(
                                                                                        feedbackItem,
                                                                                        _vm.item
                                                                                      )
                                                                                    },
                                                                                },
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "\n                            删除\n                          "
                                                                                ),
                                                                              ]
                                                                            )
                                                                          : _vm._e(),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e(),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          height: "18px",
                                                          "font-size": "12px",
                                                          "line-height": "18px",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                    " +
                                                            _vm._s(
                                                              feedbackItem.createTime
                                                            ) +
                                                            "\n                  "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  "padding-left": "25px",
                                                  "padding-top": "5px",
                                                  "padding-bottom": "5px",
                                                },
                                              },
                                              [
                                                !feedbackItem.editble
                                                  ? _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          feedbackItem.feedback
                                                        )
                                                      ),
                                                    ])
                                                  : _c("a-textarea", {
                                                      attrs: {
                                                        disabled:
                                                          (!_vm.checkPermission(
                                                            "fk:edit"
                                                          ) ||
                                                            !_vm.changeAll) &&
                                                          feedbackItem.userId !=
                                                            _vm.userInfo.id,
                                                        "auto-size": {
                                                          minRows: 1,
                                                          maxRows: 10,
                                                        },
                                                        placeholder:
                                                          "ALT+回车换行，回车发送",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                        },
                                                        focus: _vm.inputFocus,
                                                        blur: _vm.inputBlur,
                                                        keydown: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            !$event.type.indexOf(
                                                              "key"
                                                            ) &&
                                                            _vm._k(
                                                              $event.keyCode,
                                                              "enter",
                                                              13,
                                                              $event.key,
                                                              "Enter"
                                                            )
                                                          )
                                                            return null
                                                          return ((e) => {
                                                            _vm.feedbackItemEditEnter(
                                                              e,
                                                              feedbackItem,
                                                              _vm.item
                                                            )
                                                          }).apply(
                                                            null,
                                                            arguments
                                                          )
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          feedbackItem.feedback,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            feedbackItem,
                                                            "feedback",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "feedbackItem.feedback",
                                                      },
                                                    }),
                                              ],
                                              1
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  )
                                : _vm._e(),
                              _vm.selectErrorType == 4 &&
                              _vm.topRejectComments &&
                              _vm.topRejectComments.length
                                ? _c(
                                    "div",
                                    { staticStyle: { "padding-left": "10px" } },
                                    _vm._l(
                                      _vm.topRejectComments,
                                      function (rejectItem) {
                                        return _c(
                                          "div",
                                          { key: rejectItem.id + "revision" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "revisition-content",
                                                staticStyle: {
                                                  display: "flex",
                                                  "align-items": "center",
                                                  color: "rgba(0, 0, 0, 0.65)",
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      "margin-right": "5px",
                                                    },
                                                  },
                                                  [
                                                    _c("img", {
                                                      attrs: {
                                                        src: rejectItem.avatar,
                                                        alt: "",
                                                      },
                                                    }),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: { flex: "1" },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "revisition-content-error",
                                                        staticStyle: {
                                                          height: "20px",
                                                          "font-size": "12px",
                                                        },
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              rejectItem.username
                                                            )
                                                          ),
                                                        ]),
                                                        _c(
                                                          "span",
                                                          {
                                                            class:
                                                              "action-span",
                                                          },
                                                          [
                                                            (rejectItem.userId ==
                                                              _vm.userInfo.id ||
                                                              _vm.checkPermission(
                                                                "reject:edit"
                                                              ) ||
                                                              _vm.checkPermission(
                                                                "reject:dele"
                                                              )) &&
                                                            _vm.changeAll
                                                              ? _c(
                                                                  "a-dropdown",
                                                                  {
                                                                    attrs: {
                                                                      trigger: [
                                                                        "click",
                                                                      ],
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "a-icon",
                                                                      {
                                                                        attrs: {
                                                                          type: "more",
                                                                        },
                                                                      }
                                                                    ),
                                                                    _c(
                                                                      "a-menu",
                                                                      {
                                                                        attrs: {
                                                                          slot: "overlay",
                                                                        },
                                                                        slot: "overlay",
                                                                      },
                                                                      [
                                                                        rejectItem.userId ==
                                                                          _vm
                                                                            .userInfo
                                                                            .id ||
                                                                        _vm.checkPermission(
                                                                          "reject:edit"
                                                                        )
                                                                          ? _c(
                                                                              "a-menu-item",
                                                                              {
                                                                                key: "0",
                                                                                on: {
                                                                                  click:
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.editLocalContent(
                                                                                        rejectItem
                                                                                      )
                                                                                    },
                                                                                  mousemove:
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      _vm.isDelete = true
                                                                                    },
                                                                                  mouseout:
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      _vm.isDelete = false
                                                                                    },
                                                                                },
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "\n                            编辑\n                          "
                                                                                ),
                                                                              ]
                                                                            )
                                                                          : _vm._e(),
                                                                        rejectItem.userId ==
                                                                          _vm
                                                                            .userInfo
                                                                            .id ||
                                                                        _vm.checkPermission(
                                                                          "reject:dele"
                                                                        )
                                                                          ? _c(
                                                                              "a-menu-item",
                                                                              {
                                                                                key: "1",
                                                                                on: {
                                                                                  mousemove:
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      _vm.isDelete = true
                                                                                    },
                                                                                  mouseout:
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      _vm.isDelete = false
                                                                                    },
                                                                                  click:
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.deletReject(
                                                                                        rejectItem,
                                                                                        _vm.item
                                                                                      )
                                                                                    },
                                                                                },
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "\n                            删除\n                          "
                                                                                ),
                                                                              ]
                                                                            )
                                                                          : _vm._e(),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e(),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          height: "18px",
                                                          "font-size": "12px",
                                                          "line-height": "18px",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                    " +
                                                            _vm._s(
                                                              rejectItem.createTime
                                                            ) +
                                                            "\n                  "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  "padding-left": "25px",
                                                  "padding-top": "5px",
                                                  "padding-bottom": "5px",
                                                },
                                              },
                                              [
                                                !rejectItem.editble
                                                  ? _c(
                                                      "span",
                                                      {
                                                        staticStyle: {
                                                          color:
                                                            "rgba(0, 0, 0, 0.65)",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            rejectItem.comment
                                                          )
                                                        ),
                                                      ]
                                                    )
                                                  : rejectItem.editble
                                                  ? _c("a-textarea", {
                                                      staticStyle: {
                                                        "padding-left":
                                                          "0 !important",
                                                      },
                                                      attrs: {
                                                        disabled:
                                                          (!_vm.checkPermission(
                                                            "reject:edit"
                                                          ) ||
                                                            !_vm.changeAll) &&
                                                          rejectItem.userId !=
                                                            _vm.userInfo.id,
                                                        "auto-size": {
                                                          minRows: 1,
                                                          maxRows: 10,
                                                        },
                                                        placeholder:
                                                          "ALT+回车换行，回车发送",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                        },
                                                        focus: _vm.inputFocus,
                                                        blur: _vm.inputBlur,
                                                        keydown: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            !$event.type.indexOf(
                                                              "key"
                                                            ) &&
                                                            _vm._k(
                                                              $event.keyCode,
                                                              "enter",
                                                              13,
                                                              $event.key,
                                                              "Enter"
                                                            )
                                                          )
                                                            return null
                                                          return ((e) => {
                                                            _vm.rejectEnter(
                                                              e,
                                                              rejectItem,
                                                              _vm.item
                                                            )
                                                          }).apply(
                                                            null,
                                                            arguments
                                                          )
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          rejectItem.comment,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            rejectItem,
                                                            "comment",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "rejectItem.comment",
                                                      },
                                                    })
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  )
                                : _vm._e(),
                              _vm.selectErrorType > 0 && _vm.selectErrorType < 4
                                ? _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        "padding-left": "12px",
                                        "padding-bottom": "10px",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(
                                            (_vm.selectErrorType == 1 &&
                                              _vm.topInjuryType == 1) ||
                                              (_vm.selectErrorType == 2 &&
                                                _vm.topInjuryType == 2) ||
                                              (_vm.selectErrorType == 3 &&
                                                _vm.topInjuryType == 3)
                                              ? _vm.topInjuryRemark
                                              : ""
                                          ) +
                                          "\n          "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]),
                            _vm.selectErrorType != 4 && _vm.selectErrorType
                              ? _c(
                                  "a-select",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      disabled:
                                        _vm.taskDetail.stageName == "翻译",
                                      "show-search": "",
                                      placeholder: "请选择错误类型",
                                    },
                                    on: {
                                      mousemove: function ($event) {
                                        _vm.isDelete = true
                                      },
                                      change: function ($event) {
                                        return _vm.errorChange(_vm.item)
                                      },
                                    },
                                    model: {
                                      value: _vm.errorValue,
                                      callback: function ($$v) {
                                        _vm.errorValue = $$v
                                      },
                                      expression: "errorValue",
                                    },
                                  },
                                  _vm._l(_vm.dictList, function (errorItem) {
                                    return _c(
                                      "a-select-option",
                                      {
                                        key: errorItem.id,
                                        attrs: { value: errorItem.itemText },
                                        on: {
                                          mousemove: function ($event) {
                                            _vm.isDelete = true
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n            " +
                                            _vm._s(errorItem.itemText) +
                                            "\n          "
                                        ),
                                      ]
                                    )
                                  }),
                                  1
                                )
                              : _c("a-textarea", {
                                  attrs: {
                                    disabled:
                                      _vm.taskDetail.stageName == "翻译",
                                    placeholder: "ALT+回车换行，回车提交",
                                    "auto-size": { minRows: 1, maxRows: 10 },
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                    },
                                    focus: _vm.inputFocus,
                                    blur: _vm.inputBlur,
                                    keydown: function ($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      )
                                        return null
                                      return ((e) => {
                                        _vm.errorEnter(
                                          e,
                                          _vm.item,
                                          _vm.selectErrorType
                                        )
                                      }).apply(null, arguments)
                                    },
                                  },
                                  model: {
                                    value: _vm.errorValue,
                                    callback: function ($$v) {
                                      _vm.errorValue = $$v
                                    },
                                    expression: "errorValue",
                                  },
                                }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  (_vm.taskDetail.stageName == "终审" ||
                    _vm.taskDetail.stageName == "校对") &&
                  _vm.changeAll
                    ? _c("div", { staticClass: "ocr-show-item-action-top" }, [
                        _c(
                          "div",
                          { staticClass: "ocr-show-item-action-title" },
                          [_vm._v("【终审】对【校对】软硬伤标记")]
                        ),
                        _c(
                          "div",
                          { staticClass: "ocr-show-item-action-radio" },
                          [
                            _c(
                              "span",
                              {
                                class: !_vm.selectErrorTypeBottom
                                  ? "error-span"
                                  : "no-error-span",
                                style:
                                  _vm.taskDetail.stageName == "校对"
                                    ? "cursor: no-drop;"
                                    : "",
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    _vm.taskDetail.stageName == "校对"
                                      ? () => {}
                                      : _vm.changeInjuryTypeBottom(0)
                                  },
                                },
                              },
                              [
                                _vm._v("无"),
                                _vm.selectBottomErrorTypes.some((s) => s == 0)
                                  ? _c("a-icon", {
                                      attrs: {
                                        type: "check-circle",
                                        theme: "filled",
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "span",
                              {
                                class:
                                  _vm.selectErrorTypeBottom == 3
                                    ? "error-span"
                                    : "no-error-span",
                                style:
                                  _vm.taskDetail.stageName == "校对"
                                    ? "color: red; margin-left: 3px;cursor: no-drop;"
                                    : "color: red; margin-left: 3px",
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    _vm.taskDetail.stageName == "校对"
                                      ? () => {}
                                      : _vm.changeInjuryTypeBottom(3)
                                  },
                                },
                              },
                              [
                                _vm._v("错"),
                                _vm.selectBottomErrorTypes.some((s) => s == 3)
                                  ? _c("a-icon", {
                                      attrs: {
                                        type: "check-circle",
                                        theme: "filled",
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "span",
                              {
                                class:
                                  _vm.selectErrorTypeBottom == 2
                                    ? "error-span"
                                    : "no-error-span",
                                style:
                                  _vm.taskDetail.stageName == "校对"
                                    ? "color: red; margin-left: 3px;cursor: no-drop;"
                                    : "color: red; margin-left: 3px",
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    _vm.taskDetail.stageName == "校对"
                                      ? () => {}
                                      : _vm.changeInjuryTypeBottom(2)
                                  },
                                },
                              },
                              [
                                _vm._v("硬"),
                                _vm.selectBottomErrorTypes.some((s) => s == 2)
                                  ? _c("a-icon", {
                                      attrs: {
                                        type: "check-circle",
                                        theme: "filled",
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "span",
                              {
                                class:
                                  _vm.selectErrorTypeBottom == 1
                                    ? "error-span"
                                    : "no-error-span",
                                style:
                                  _vm.taskDetail.stageName == "校对"
                                    ? "color: red; margin-left: 3px;cursor: no-drop;"
                                    : "color: red; margin-left: 3px;",
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    _vm.taskDetail.stageName == "校对"
                                      ? () => {}
                                      : _vm.changeInjuryTypeBottom(1)
                                  },
                                },
                              },
                              [
                                _vm._v("软"),
                                _vm.selectBottomErrorTypes.some((s) => s == 1)
                                  ? _c("a-icon", {
                                      attrs: {
                                        type: "check-circle",
                                        theme: "filled",
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "span",
                              {
                                class:
                                  _vm.selectErrorTypeBottom == 4
                                    ? "error-span"
                                    : "no-error-span",
                                style:
                                  _vm.taskDetail.stageName == "校对"
                                    ? "color: orange; margin-left: 3px;cursor: no-drop;"
                                    : "color: orange; margin-left: 3px",
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    _vm.taskDetail.stageName == "校对"
                                      ? () => {}
                                      : _vm.changeInjuryTypeBottom(4)
                                  },
                                },
                              },
                              [
                                _vm._v("驳"),
                                _vm.selectBottomErrorTypes.some((s) => s == 4)
                                  ? _c("a-icon", {
                                      attrs: {
                                        type: "check-circle",
                                        theme: "filled",
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "ocr-show-item-action-input",
                            on: {
                              mousemove: function ($event) {
                                _vm.isDelete = true
                              },
                              mouseout: function ($event) {
                                _vm.isDelete = false
                              },
                            },
                          },
                          [
                            _c("div", [
                              !_vm.selectErrorTypeBottom
                                ? _c(
                                    "div",
                                    { staticStyle: { "padding-left": "10px" } },
                                    _vm._l(
                                      _vm.bottomFeedbacks,
                                      function (feedbackItem) {
                                        return _c(
                                          "div",
                                          { key: feedbackItem.id },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "revisition-content",
                                                staticStyle: {
                                                  display: "flex",
                                                  "align-items": "center",
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      "margin-right": "5px",
                                                    },
                                                  },
                                                  [
                                                    _c("img", {
                                                      attrs: {
                                                        src: feedbackItem.avatar,
                                                        alt: "",
                                                      },
                                                    }),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: { flex: "1" },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "revisition-content-error",
                                                        staticStyle: {
                                                          height: "18px",
                                                          "font-size": "12px",
                                                        },
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              feedbackItem.username
                                                            )
                                                          ),
                                                        ]),
                                                        _c(
                                                          "span",
                                                          {
                                                            class:
                                                              "action-span",
                                                          },
                                                          [
                                                            (_vm.checkPermission(
                                                              "fk:edit"
                                                            ) ||
                                                              _vm.checkPermission(
                                                                "fk:dele"
                                                              ) ||
                                                              feedbackItem.userId ==
                                                                _vm.userInfo
                                                                  .id) &&
                                                            _vm.changeAll
                                                              ? _c(
                                                                  "a-dropdown",
                                                                  {
                                                                    attrs: {
                                                                      trigger: [
                                                                        "click",
                                                                      ],
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "a-icon",
                                                                      {
                                                                        attrs: {
                                                                          type: "more",
                                                                        },
                                                                      }
                                                                    ),
                                                                    _c(
                                                                      "a-menu",
                                                                      {
                                                                        attrs: {
                                                                          slot: "overlay",
                                                                        },
                                                                        slot: "overlay",
                                                                      },
                                                                      [
                                                                        feedbackItem.userId ==
                                                                          _vm
                                                                            .userInfo
                                                                            .id ||
                                                                        _vm.checkPermission(
                                                                          "fk:edit"
                                                                        )
                                                                          ? _c(
                                                                              "a-menu-item",
                                                                              {
                                                                                key: "0",
                                                                                on: {
                                                                                  mousemove:
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      _vm.isDelete = true
                                                                                    },
                                                                                  mouseout:
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      _vm.isDelete = false
                                                                                    },
                                                                                  click:
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.editLocalContent(
                                                                                        feedbackItem
                                                                                      )
                                                                                    },
                                                                                },
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "\n                            编辑\n                          "
                                                                                ),
                                                                              ]
                                                                            )
                                                                          : _vm._e(),
                                                                        feedbackItem.userId ==
                                                                          _vm
                                                                            .userInfo
                                                                            .id ||
                                                                        _vm.checkPermission(
                                                                          "fk:dele"
                                                                        )
                                                                          ? _c(
                                                                              "a-menu-item",
                                                                              {
                                                                                key: "1",
                                                                                on: {
                                                                                  mousemove:
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      _vm.isDelete = true
                                                                                    },
                                                                                  mouseout:
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      _vm.isDelete = false
                                                                                    },
                                                                                  click:
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.deletFeedback(
                                                                                        feedbackItem,
                                                                                        _vm.item
                                                                                      )
                                                                                    },
                                                                                },
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "\n                            删除\n                          "
                                                                                ),
                                                                              ]
                                                                            )
                                                                          : _vm._e(),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e(),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          height: "18px",
                                                          "font-size": "12px",
                                                          "line-height": "18px",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                    " +
                                                            _vm._s(
                                                              feedbackItem.createTime
                                                            ) +
                                                            "\n                  "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  "padding-left": "25px",
                                                  "padding-top": "5px",
                                                  "padding-bottom": "5px",
                                                },
                                              },
                                              [
                                                !feedbackItem.editble
                                                  ? _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          feedbackItem.feedback
                                                        )
                                                      ),
                                                    ])
                                                  : _c("a-textarea", {
                                                      attrs: {
                                                        disabled:
                                                          (!_vm.checkPermission(
                                                            "fk:edit"
                                                          ) ||
                                                            !_vm.changeAll) &&
                                                          feedbackItem.userId !=
                                                            _vm.userInfo.id,
                                                        "auto-size": {
                                                          minRows: 1,
                                                          maxRows: 10,
                                                        },
                                                        placeholder:
                                                          "ALT+回车换行，回车发送",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                        },
                                                        focus: _vm.inputFocus,
                                                        blur: _vm.inputBlur,
                                                        keydown: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            !$event.type.indexOf(
                                                              "key"
                                                            ) &&
                                                            _vm._k(
                                                              $event.keyCode,
                                                              "enter",
                                                              13,
                                                              $event.key,
                                                              "Enter"
                                                            )
                                                          )
                                                            return null
                                                          return ((e) => {
                                                            _vm.feedbackItemEditEnter(
                                                              e,
                                                              feedbackItem,
                                                              _vm.item
                                                            )
                                                          }).apply(
                                                            null,
                                                            arguments
                                                          )
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          feedbackItem.feedback,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            feedbackItem,
                                                            "feedback",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "feedbackItem.feedback",
                                                      },
                                                    }),
                                              ],
                                              1
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  )
                                : _vm._e(),
                              _vm.selectErrorTypeBottom == 4 &&
                              _vm.bottomRejectComments &&
                              _vm.bottomRejectComments.length
                                ? _c(
                                    "div",
                                    { staticStyle: { "padding-left": "10px" } },
                                    _vm._l(
                                      _vm.bottomRejectComments,
                                      function (rejectItem) {
                                        return _c(
                                          "div",
                                          { key: rejectItem.id + "revision" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "revisition-content",
                                                staticStyle: {
                                                  display: "flex",
                                                  "align-items": "center",
                                                  color: "rgba(0, 0, 0, 0.65)",
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      "margin-right": "5px",
                                                    },
                                                  },
                                                  [
                                                    _c("img", {
                                                      attrs: {
                                                        src: rejectItem.avatar,
                                                        alt: "",
                                                      },
                                                    }),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: { flex: "1" },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "revisition-content-error",
                                                        staticStyle: {
                                                          height: "20px",
                                                          "font-size": "12px",
                                                        },
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              rejectItem.username
                                                            )
                                                          ),
                                                        ]),
                                                        _c(
                                                          "span",
                                                          {
                                                            class:
                                                              "action-span",
                                                          },
                                                          [
                                                            (rejectItem.userId ==
                                                              _vm.userInfo.id ||
                                                              _vm.checkPermission(
                                                                "reject:edit"
                                                              ) ||
                                                              _vm.checkPermission(
                                                                "reject:dele"
                                                              )) &&
                                                            _vm.changeAll
                                                              ? _c(
                                                                  "a-dropdown",
                                                                  {
                                                                    attrs: {
                                                                      trigger: [
                                                                        "click",
                                                                      ],
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "a-icon",
                                                                      {
                                                                        attrs: {
                                                                          type: "more",
                                                                        },
                                                                      }
                                                                    ),
                                                                    _c(
                                                                      "a-menu",
                                                                      {
                                                                        attrs: {
                                                                          slot: "overlay",
                                                                        },
                                                                        slot: "overlay",
                                                                      },
                                                                      [
                                                                        rejectItem.userId ==
                                                                          _vm
                                                                            .userInfo
                                                                            .id ||
                                                                        _vm.checkPermission(
                                                                          "reject:edit"
                                                                        )
                                                                          ? _c(
                                                                              "a-menu-item",
                                                                              {
                                                                                key: "0",
                                                                                on: {
                                                                                  click:
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.editLocalContent(
                                                                                        rejectItem
                                                                                      )
                                                                                    },
                                                                                  mousemove:
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      _vm.isDelete = true
                                                                                    },
                                                                                  mouseout:
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      _vm.isDelete = false
                                                                                    },
                                                                                },
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "\n                            编辑\n                          "
                                                                                ),
                                                                              ]
                                                                            )
                                                                          : _vm._e(),
                                                                        rejectItem.userId ==
                                                                          _vm
                                                                            .userInfo
                                                                            .id ||
                                                                        _vm.checkPermission(
                                                                          "reject:dele"
                                                                        )
                                                                          ? _c(
                                                                              "a-menu-item",
                                                                              {
                                                                                key: "1",
                                                                                on: {
                                                                                  mousemove:
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      _vm.isDelete = true
                                                                                    },
                                                                                  mouseout:
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      _vm.isDelete = false
                                                                                    },
                                                                                  click:
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.deletReject(
                                                                                        rejectItem,
                                                                                        _vm.item
                                                                                      )
                                                                                    },
                                                                                },
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "\n                            删除\n                          "
                                                                                ),
                                                                              ]
                                                                            )
                                                                          : _vm._e(),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e(),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          height: "18px",
                                                          "font-size": "12px",
                                                          "line-height": "18px",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                    " +
                                                            _vm._s(
                                                              rejectItem.createTime
                                                            ) +
                                                            "\n                  "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  "padding-left": "25px",
                                                  "padding-top": "5px",
                                                  "padding-bottom": "5px",
                                                },
                                              },
                                              [
                                                !rejectItem.editble
                                                  ? _c(
                                                      "span",
                                                      {
                                                        staticStyle: {
                                                          color:
                                                            "rgba(0, 0, 0, 0.65)",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            rejectItem.comment
                                                          )
                                                        ),
                                                      ]
                                                    )
                                                  : rejectItem.editble
                                                  ? _c("a-textarea", {
                                                      staticStyle: {
                                                        "padding-left":
                                                          "0 !important",
                                                      },
                                                      attrs: {
                                                        disabled:
                                                          (!_vm.checkPermission(
                                                            "reject:edit"
                                                          ) ||
                                                            !_vm.changeAll) &&
                                                          rejectItem.userId !=
                                                            _vm.userInfo.id,
                                                        "auto-size": {
                                                          minRows: 1,
                                                          maxRows: 10,
                                                        },
                                                        placeholder:
                                                          "ALT+回车换行，回车发送",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                        },
                                                        focus: _vm.inputFocus,
                                                        blur: _vm.inputBlur,
                                                        keydown: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            !$event.type.indexOf(
                                                              "key"
                                                            ) &&
                                                            _vm._k(
                                                              $event.keyCode,
                                                              "enter",
                                                              13,
                                                              $event.key,
                                                              "Enter"
                                                            )
                                                          )
                                                            return null
                                                          return ((e) => {
                                                            _vm.rejectEnter(
                                                              e,
                                                              rejectItem,
                                                              _vm.item
                                                            )
                                                          }).apply(
                                                            null,
                                                            arguments
                                                          )
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          rejectItem.comment,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            rejectItem,
                                                            "comment",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "rejectItem.comment",
                                                      },
                                                    })
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  )
                                : _vm._e(),
                              _vm.selectErrorTypeBottom > 0 &&
                              _vm.selectErrorTypeBottom < 4
                                ? _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        "padding-left": "12px",
                                        "padding-bottom": "10px",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(
                                            (_vm.selectErrorTypeBottom == 1 &&
                                              _vm.bottomInjuryType == 1) ||
                                              (_vm.selectErrorTypeBottom == 2 &&
                                                _vm.bottomInjuryType == 2) ||
                                              (_vm.selectErrorTypeBottom == 3 &&
                                                _vm.bottomInjuryType == 3)
                                              ? _vm.bottomInjuryRemark
                                              : ""
                                          ) +
                                          "\n          "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]),
                            _vm.selectErrorTypeBottom != 4 &&
                            _vm.selectErrorTypeBottom
                              ? _c(
                                  "a-select",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      disabled:
                                        _vm.taskDetail.stageName == "校对",
                                      "show-search": "",
                                      placeholder: "请选择错误类型",
                                    },
                                    on: {
                                      mousemove: function ($event) {
                                        _vm.isDelete = true
                                      },
                                      change: function ($event) {
                                        return _vm.errorChangeBottom(_vm.item)
                                      },
                                    },
                                    model: {
                                      value: _vm.errorValueBottom,
                                      callback: function ($$v) {
                                        _vm.errorValueBottom = $$v
                                      },
                                      expression: "errorValueBottom",
                                    },
                                  },
                                  _vm._l(_vm.dictList, function (errorItem) {
                                    return _c(
                                      "a-select-option",
                                      {
                                        key: errorItem.id,
                                        attrs: { value: errorItem.itemText },
                                        on: {
                                          mousemove: function ($event) {
                                            _vm.isDelete = true
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n            " +
                                            _vm._s(errorItem.itemText) +
                                            "\n          "
                                        ),
                                      ]
                                    )
                                  }),
                                  1
                                )
                              : _c("a-textarea", {
                                  attrs: {
                                    disabled:
                                      _vm.taskDetail.stageName == "校对",
                                    placeholder: "ALT+回车换行，回车提交",
                                    "auto-size": { minRows: 1, maxRows: 10 },
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                    },
                                    focus: _vm.inputFocus,
                                    blur: _vm.inputBlur,
                                    keydown: function ($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      )
                                        return null
                                      return ((e) => {
                                        _vm.errorEnterBottom(
                                          e,
                                          _vm.item,
                                          _vm.selectErrorTypeBottom
                                        )
                                      }).apply(null, arguments)
                                    },
                                  },
                                  model: {
                                    value: _vm.errorValueBottom,
                                    callback: function ($$v) {
                                      _vm.errorValueBottom = $$v
                                    },
                                    expression: "errorValueBottom",
                                  },
                                }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _vm.glossaryTags.filter(
                    (w) =>
                      (_vm.item.originalText &&
                        (_vm.item.originalText
                          .replaceAll("\n", "")
                          .indexOf(w.value) > -1 ||
                          w.value.indexOf(
                            _vm.item.originalText.replaceAll("\n", "")
                          ) > -1)) ||
                      (_vm.item.showText &&
                        (_vm.item.showText
                          .replaceAll("\n", "")
                          .indexOf(w.target) > -1 ||
                          w.target.indexOf(
                            _vm.item.showText.replaceAll("\n", "")
                          ) > -1))
                  ).length ||
                  _vm.terms.filter(
                    (w) =>
                      (_vm.item.originalText &&
                        _vm.item.originalText.length > 1 &&
                        w.valueX &&
                        (_vm.item.originalText
                          .replaceAll("\n", "")
                          .indexOf(w.valueX) > -1 ||
                          w.valueX.indexOf(
                            _vm.item.originalText.replaceAll("\n", "")
                          ) > -1)) ||
                      (_vm.item.originalText &&
                        _vm.item.originalText.length > 1 &&
                        w.valueM &&
                        (_vm.item.originalText
                          .replaceAll("\n", "")
                          .indexOf(w.valueM) > -1 ||
                          w.valueM.indexOf(
                            _vm.item.originalText.replaceAll("\n", "")
                          ) > -1)) ||
                      (_vm.item.showText &&
                        _vm.item.showText.length > 1 &&
                        w.targetX &&
                        (_vm.item.showText
                          .replaceAll("\n", "")
                          .indexOf(w.targetX) > -1 ||
                          w.targetX.indexOf(
                            _vm.item.showText.replaceAll("\n", "")
                          ) > -1)) ||
                      (_vm.item.showText &&
                        _vm.item.showText.length > 1 &&
                        w.targetM &&
                        (_vm.item.showText
                          .replaceAll("\n", "")
                          .indexOf(w.targetM) > -1 ||
                          w.targetM.indexOf(
                            _vm.item.showText.replaceAll("\n", "")
                          ) > -1)) ||
                      (_vm.item.showText &&
                        _vm.item.showText.length > 1 &&
                        w.target &&
                        (_vm.item.showText
                          .replaceAll("\n", "")
                          .indexOf(w.target) > -1 ||
                          w.target.indexOf(
                            _vm.item.showText.replaceAll("\n", "")
                          ) > -1)) ||
                      (_vm.item.originalText &&
                        _vm.item.originalText.length > 1 &&
                        w.value &&
                        (_vm.item.originalText
                          .replaceAll("\n", "")
                          .indexOf(w.value) > -1 ||
                          w.value.indexOf(
                            _vm.item.originalText.replaceAll("\n", "")
                          ) > -1))
                  ).length ||
                  _vm.termBacks.filter(
                    (w) =>
                      (_vm.item.originalText &&
                        _vm.item.originalText.length > 1 &&
                        (_vm.item.originalText
                          .replaceAll("\n", "")
                          .indexOf(w.value) > -1 ||
                          w.value.indexOf(
                            _vm.item.originalText.replaceAll("\n", "")
                          ) > -1)) ||
                      (_vm.item.showText &&
                        _vm.item.showText.length > 1 &&
                        (_vm.item.showText
                          .replaceAll("\n", "")
                          .indexOf(w.target) > -1 ||
                          w.target.indexOf(
                            _vm.item.showText.replaceAll("\n", "")
                          ) > -1))
                  ).length ||
                  _vm.termNotes.filter(
                    (w) =>
                      w.target &&
                      (_vm.item.showText.replaceAll("\n", "").indexOf(w.value) >
                        -1 ||
                        _vm.item.showText
                          .replaceAll("\n", "")
                          .indexOf(w.target) > -1)
                  ).length ||
                  _vm.yahooOrKdxList.length ||
                  _vm.termsPhonetics.length ||
                  _vm.blacklist.some(
                    (words) => _vm.getBlackWordsTwo(_vm.item, words).length
                  ) ||
                  _vm.blacklist.some(
                    (words) => _vm.getBlackWords(_vm.item, words).length
                  )
                    ? _c(
                        "div",
                        {
                          staticClass: "ocr-show-item-action-bottom",
                          staticStyle: { "font-size": "12px" },
                        },
                        [
                          _vm.termsPhonetics.length
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        "font-weight": "bold",
                                        "padding-bottom": "8px",
                                      },
                                    },
                                    [_vm._v("自动注音情况：")]
                                  ),
                                  _vm._l(
                                    _vm.termsPhonetics,
                                    function (word, wordIdx) {
                                      return _c(
                                        "div",
                                        {
                                          key: wordIdx,
                                          staticStyle: {
                                            "padding-bottom": "8px",
                                            "justify-content": "space-between",
                                            display: "flex",
                                          },
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              "\n            " +
                                                _vm._s(
                                                  word.autoPhoneticTrigger == 0
                                                    ? "作品中每次："
                                                    : word.autoPhoneticTrigger ==
                                                      1
                                                    ? "作品中首次："
                                                    : word.autoPhoneticTrigger ==
                                                      2
                                                    ? "每话首次："
                                                    : word.autoPhoneticTrigger ==
                                                      3
                                                    ? "每页首次："
                                                    : word.autoPhoneticTrigger ==
                                                      4
                                                    ? "人员校正："
                                                    : ""
                                                ) +
                                                _vm._s(
                                                  `${
                                                    word.processedName ||
                                                    "无译文名"
                                                  }(${word.phoneticText})`
                                                ) +
                                                "\n          "
                                            ),
                                          ]),
                                          _c("span", [
                                            _vm.changeAll
                                              ? _c(
                                                  "a",
                                                  {
                                                    on: {
                                                      click: function ($event) {
                                                        $event.stopPropagation()
                                                        return _vm.addPhonetic(
                                                          _vm.item,
                                                          word
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.getIsAddPhoneticText(
                                                          _vm.item,
                                                          word
                                                        )
                                                          ? "撤销"
                                                          : "注音"
                                                      )
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]),
                                        ]
                                      )
                                    }
                                  ),
                                ],
                                2
                              )
                            : _vm._e(),
                          _vm.termNotes.filter(
                            (w) =>
                              w.target &&
                              (_vm.item.showText
                                .replaceAll("\n", "")
                                .indexOf(w.value) > -1 ||
                                _vm.item.showText
                                  .replaceAll("\n", "")
                                  .indexOf(w.target) > -1)
                          ).length
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        "font-weight": "bold",
                                        "padding-bottom": "8px",
                                      },
                                    },
                                    [_vm._v("表记：")]
                                  ),
                                  _vm._l(
                                    _vm.termNotes.filter(
                                      (w) =>
                                        w.target &&
                                        (_vm.item.showText
                                          .replaceAll("\n", "")
                                          .indexOf(w.value) > -1 ||
                                          _vm.item.showText
                                            .replaceAll("\n", "")
                                            .indexOf(w.target) > -1)
                                    ),
                                    function (word, wordIdx) {
                                      return _c(
                                        "div",
                                        {
                                          key: wordIdx,
                                          staticStyle: {
                                            "padding-bottom": "8px",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "justify-content":
                                                  "space-between",
                                                display: "flex",
                                              },
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    word.value +
                                                      "→" +
                                                      word.target
                                                  )
                                                ),
                                              ]),
                                              _c("span", [
                                                _vm.changeAll &&
                                                _vm.getIsAddTableNote(
                                                  _vm.item,
                                                  word
                                                )
                                                  ? _c(
                                                      "a",
                                                      {
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            $event.stopPropagation()
                                                            return _vm.addTableNote(
                                                              _vm.item,
                                                              word
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [_vm._v("替换")]
                                                    )
                                                  : _vm._e(),
                                              ]),
                                            ]
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                ],
                                2
                              )
                            : _vm._e(),
                          _vm.glossaryTags.filter(
                            (w) =>
                              (_vm.item.originalText &&
                                (_vm.item.originalText
                                  .replaceAll("\n", "")
                                  .indexOf(w.value) > -1 ||
                                  w.value.indexOf(
                                    _vm.item.originalText.replaceAll("\n", "")
                                  ) > -1)) ||
                              (_vm.item.showText &&
                                (_vm.item.showText
                                  .replaceAll("\n", "")
                                  .indexOf(w.target) > -1 ||
                                  w.target.indexOf(
                                    _vm.item.showText.replaceAll("\n", "")
                                  ) > -1))
                          ).length
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        color: "green",
                                        "font-weight": "bold",
                                        "padding-bottom": "8px",
                                      },
                                    },
                                    [_vm._v("专有词汇：")]
                                  ),
                                  _vm._l(
                                    _vm.glossaryTags.filter(
                                      (w) =>
                                        (_vm.item.originalText &&
                                          (_vm.item.originalText
                                            .replaceAll("\n", "")
                                            .indexOf(w.value) > -1 ||
                                            w.value.indexOf(
                                              _vm.item.originalText.replaceAll(
                                                "\n",
                                                ""
                                              )
                                            ) > -1)) ||
                                        (_vm.item.showText &&
                                          (_vm.item.showText
                                            .replaceAll("\n", "")
                                            .indexOf(w.target) > -1 ||
                                            w.target.indexOf(
                                              _vm.item.showText.replaceAll(
                                                "\n",
                                                ""
                                              )
                                            ) > -1))
                                    ),
                                    function (word, wordIdx) {
                                      return _c(
                                        "div",
                                        {
                                          key: wordIdx,
                                          staticStyle: {
                                            "padding-bottom": "8px",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n          " +
                                              _vm._s(
                                                word.value + "→" + word.target
                                              ) +
                                              "\n        "
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                ],
                                2
                              )
                            : _vm._e(),
                          _vm.terms.filter(
                            (w) =>
                              (_vm.item.originalText &&
                                _vm.item.originalText.length > 1 &&
                                w.valueX &&
                                (_vm.item.originalText
                                  .replaceAll("\n", "")
                                  .indexOf(w.valueX) > -1 ||
                                  w.valueX.indexOf(
                                    _vm.item.originalText.replaceAll("\n", "")
                                  ) > -1)) ||
                              (_vm.item.originalText &&
                                _vm.item.originalText.length > 1 &&
                                w.valueM &&
                                (_vm.item.originalText
                                  .replaceAll("\n", "")
                                  .indexOf(w.valueM) > -1 ||
                                  w.valueM.indexOf(
                                    _vm.item.originalText.replaceAll("\n", "")
                                  ) > -1)) ||
                              (_vm.item.showText &&
                                _vm.item.showText.length > 1 &&
                                w.targetX &&
                                (_vm.item.showText
                                  .replaceAll("\n", "")
                                  .indexOf(w.targetX) > -1 ||
                                  w.targetX.indexOf(
                                    _vm.item.showText.replaceAll("\n", "")
                                  ) > -1)) ||
                              (_vm.item.showText &&
                                _vm.item.showText.length > 1 &&
                                w.targetM &&
                                (_vm.item.showText
                                  .replaceAll("\n", "")
                                  .indexOf(w.targetM) > -1 ||
                                  w.targetM.indexOf(
                                    _vm.item.showText.replaceAll("\n", "")
                                  ) > -1)) ||
                              (_vm.item.showText &&
                                _vm.item.showText.length > 1 &&
                                w.target &&
                                (_vm.item.showText
                                  .replaceAll("\n", "")
                                  .indexOf(w.target) > -1 ||
                                  w.target.indexOf(
                                    _vm.item.showText.replaceAll("\n", "")
                                  ) > -1)) ||
                              (_vm.item.originalText &&
                                _vm.item.originalText.length > 1 &&
                                w.value &&
                                (_vm.item.originalText
                                  .replaceAll("\n", "")
                                  .indexOf(w.value) > -1 ||
                                  w.value.indexOf(
                                    _vm.item.originalText.replaceAll("\n", "")
                                  ) > -1))
                          ).length
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        color: "green",
                                        "font-weight": "bold",
                                        "padding-bottom": "8px",
                                      },
                                    },
                                    [_vm._v("术语：")]
                                  ),
                                  _vm._l(
                                    _vm.terms.filter(
                                      (w) =>
                                        (_vm.item.originalText &&
                                          _vm.item.originalText.length > 1 &&
                                          w.valueX &&
                                          (_vm.item.originalText
                                            .replaceAll("\n", "")
                                            .indexOf(w.valueX) > -1 ||
                                            w.valueX.indexOf(
                                              _vm.item.originalText.replaceAll(
                                                "\n",
                                                ""
                                              )
                                            ) > -1)) ||
                                        (_vm.item.originalText &&
                                          _vm.item.originalText.length > 1 &&
                                          w.valueM &&
                                          (_vm.item.originalText
                                            .replaceAll("\n", "")
                                            .indexOf(w.valueM) > -1 ||
                                            w.valueM.indexOf(
                                              _vm.item.originalText.replaceAll(
                                                "\n",
                                                ""
                                              )
                                            ) > -1)) ||
                                        (_vm.item.showText &&
                                          _vm.item.showText.length > 1 &&
                                          w.targetX &&
                                          (_vm.item.showText
                                            .replaceAll("\n", "")
                                            .indexOf(w.targetX) > -1 ||
                                            w.targetX.indexOf(
                                              _vm.item.showText.replaceAll(
                                                "\n",
                                                ""
                                              )
                                            ) > -1)) ||
                                        (_vm.item.showText &&
                                          _vm.item.showText.length > 1 &&
                                          w.targetM &&
                                          (_vm.item.showText
                                            .replaceAll("\n", "")
                                            .indexOf(w.targetM) > -1 ||
                                            w.targetM.indexOf(
                                              _vm.item.showText.replaceAll(
                                                "\n",
                                                ""
                                              )
                                            ) > -1)) ||
                                        (_vm.item.showText &&
                                          _vm.item.showText.length > 1 &&
                                          w.target &&
                                          (_vm.item.showText
                                            .replaceAll("\n", "")
                                            .indexOf(w.target) > -1 ||
                                            w.target.indexOf(
                                              _vm.item.showText.replaceAll(
                                                "\n",
                                                ""
                                              )
                                            ) > -1)) ||
                                        (_vm.item.originalText &&
                                          _vm.item.originalText.length > 1 &&
                                          w.value &&
                                          (_vm.item.originalText
                                            .replaceAll("\n", "")
                                            .indexOf(w.value) > -1 ||
                                            w.value.indexOf(
                                              _vm.item.originalText.replaceAll(
                                                "\n",
                                                ""
                                              )
                                            ) > -1))
                                    ),
                                    function (word, wordIdx) {
                                      return _c(
                                        "div",
                                        {
                                          key: wordIdx,
                                          staticClass: "term-item",
                                          staticStyle: {
                                            "padding-bottom": "8px",
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.openTerm(word)
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n          " +
                                              _vm._s(
                                                word.value + "→" + word.target
                                              ) +
                                              "\n        "
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                ],
                                2
                              )
                            : _vm._e(),
                          _vm.termBacks.filter(
                            (w) =>
                              (_vm.item.originalText &&
                                _vm.item.originalText.length > 1 &&
                                (_vm.item.originalText
                                  .replaceAll("\n", "")
                                  .indexOf(w.value) > -1 ||
                                  w.value.indexOf(
                                    _vm.item.originalText.replaceAll("\n", "")
                                  ) > -1)) ||
                              (_vm.item.showText &&
                                _vm.item.showText.length > 1 &&
                                (_vm.item.showText
                                  .replaceAll("\n", "")
                                  .indexOf(w.target) > -1 ||
                                  w.target.indexOf(
                                    _vm.item.showText.replaceAll("\n", "")
                                  ) > -1))
                          ).length
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        color: "green",
                                        "font-weight": "bold",
                                        "padding-bottom": "8px",
                                      },
                                    },
                                    [_vm._v("背景册：")]
                                  ),
                                  _vm._l(
                                    _vm.termBacks.filter(
                                      (w) =>
                                        (_vm.item.originalText &&
                                          _vm.item.originalText.length > 1 &&
                                          (_vm.item.originalText
                                            .replaceAll("\n", "")
                                            .indexOf(w.value) > -1 ||
                                            w.value.indexOf(
                                              _vm.item.originalText.replaceAll(
                                                "\n",
                                                ""
                                              )
                                            ) > -1)) ||
                                        (_vm.item.showText &&
                                          _vm.item.showText.length > 1 &&
                                          (_vm.item.showText
                                            .replaceAll("\n", "")
                                            .indexOf(w.target) > -1 ||
                                            w.target.indexOf(
                                              _vm.item.showText.replaceAll(
                                                "\n",
                                                ""
                                              )
                                            ) > -1))
                                    ),
                                    function (word, wordIdx) {
                                      return _c(
                                        "div",
                                        {
                                          key: wordIdx,
                                          staticStyle: {
                                            "padding-bottom": "8px",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n          " +
                                              _vm._s(
                                                word.value + "→" + word.target
                                              ) +
                                              "\n        "
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                ],
                                2
                              )
                            : _vm._e(),
                          _vm.blacklist.length
                            ? _c(
                                "div",
                                [
                                  _vm._l(
                                    _vm.blacklist,
                                    function (words, wordsIdx) {
                                      return [
                                        words.classId != 12 &&
                                        _vm.getBlackWords(_vm.item, words)
                                          ? _c("div", { key: wordsIdx }, [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    color: "red",
                                                    "font-weight": "bold",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(words.className) +
                                                      "："
                                                  ),
                                                ]
                                              ),
                                              _c("div", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getBlackWords(
                                                      _vm.item,
                                                      words
                                                    )
                                                  )
                                                ),
                                              ]),
                                            ])
                                          : _vm.getBlackWordsTwo(
                                              _vm.item,
                                              words
                                            ).length
                                          ? _c(
                                              "div",
                                              { key: wordsIdx },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      color: "red",
                                                      "font-weight": "bold",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(words.className) +
                                                        "："
                                                    ),
                                                  ]
                                                ),
                                                _vm._l(
                                                  _vm.getBlackWordsTwo(
                                                    _vm.item,
                                                    words
                                                  ),
                                                  function (b, bIdx) {
                                                    return _c(
                                                      "div",
                                                      { key: bIdx },
                                                      [
                                                        _c("div", [
                                                          _vm._v(
                                                            _vm._s(b.title)
                                                          ),
                                                        ]),
                                                        _c("div", [
                                                          _vm._v(
                                                            _vm._s(b.remark)
                                                          ),
                                                        ]),
                                                      ]
                                                    )
                                                  }
                                                ),
                                              ],
                                              2
                                            )
                                          : _vm._e(),
                                      ]
                                    }
                                  ),
                                ],
                                2
                              )
                            : _vm._e(),
                          _vm.yahooOrKdxList.length
                            ? _c(
                                "div",
                                { staticStyle: { "padding-bottom": "8px" } },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        color: "red",
                                        "font-weight": "bold",
                                        "padding-bottom": "8px",
                                        "padding-top": "8px",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n          第三方纠错：\n        "
                                      ),
                                    ]
                                  ),
                                  _vm._l(
                                    _vm.yahooOrKdxList,
                                    function (words, wordsIdx) {
                                      return [
                                        words.classId != 12 &&
                                        _vm.getBlackWords(_vm.item, words)
                                          ? _c("div", { key: wordsIdx }, [
                                              _c("div", [
                                                _vm._v(
                                                  _vm._s(words.className) +
                                                    "：" +
                                                    _vm._s(
                                                      _vm.getBlackWords(
                                                        _vm.item,
                                                        words
                                                      )
                                                    )
                                                ),
                                              ]),
                                            ])
                                          : _vm.getBlackWordsTwo(
                                              _vm.item,
                                              words
                                            ).length
                                          ? _c(
                                              "div",
                                              {
                                                key: wordsIdx,
                                                staticStyle: {
                                                  display: "flex",
                                                  "padding-bottom": "8px",
                                                },
                                              },
                                              [
                                                _c("div", [
                                                  _vm._v(
                                                    _vm._s(words.className) +
                                                      "："
                                                  ),
                                                ]),
                                                _vm._l(
                                                  _vm.getBlackWordsTwo(
                                                    _vm.item,
                                                    words
                                                  ),
                                                  function (b, bIdx) {
                                                    return _c(
                                                      "div",
                                                      { key: bIdx },
                                                      [
                                                        _c("div", [
                                                          _vm._v(
                                                            _vm._s(b.title)
                                                          ),
                                                        ]),
                                                        _c("div", [
                                                          _vm._v(
                                                            _vm._s(b.remark)
                                                          ),
                                                        ]),
                                                      ]
                                                    )
                                                  }
                                                ),
                                              ],
                                              2
                                            )
                                          : _vm._e(),
                                      ]
                                    }
                                  ),
                                ],
                                2
                              )
                            : _vm._e(),
                        ]
                      )
                    : _vm._e(),
                ]
              )
            : _vm._e(),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }