<template>
  <div
    v-if="selectTextAreaData.length"
    @mousemove="changeIscheckText(true)"
    @mouseout="changeIscheckText(false)"
    class="drag-content-list"
    @mousedown="
      e => {
        mousedown(e, position)
      }
    "
    :style="
      'z-index:' +
        position.zIndex +
        ';top:' +
        (position.mouseY - 20) +
        'px' +
        (position.mouseX ? `;left:${position.mouseX}px` : ';left:194px')
    "
  >
    <div
      @mouseenter="e => leftOut(e, item)"
      :style="getCardStyle()"
      class="ocr-show-item-action"
      v-if="
        (((taskDetail.stageName == '翻译' || taskDetail.stageName == '校对' || taskDetail.stageName == '终审') &&
          changeAll) ||
          glossaryTags.filter(
            w =>
              (item.originalText &&
                (item.originalText.replaceAll('\n', '').indexOf(w.value) > -1 ||
                  w.value.indexOf(item.originalText.replaceAll('\n', '')) > -1)) ||
              (item.showText &&
                (item.showText.replaceAll('\n', '').indexOf(w.target) > -1 ||
                  w.target.indexOf(item.showText.replaceAll('\n', '')) > -1))
          ).length ||
          terms.filter(
            w =>
              (item.originalText &&
                item.originalText.length > 1 &&
                w.valueX &&
                (item.originalText.replaceAll('\n', '').indexOf(w.valueX) > -1 ||
                  w.valueX.indexOf(item.originalText.replaceAll('\n', '')) > -1)) ||
              (item.originalText &&
                item.originalText.length > 1 &&
                w.valueM &&
                (item.originalText.replaceAll('\n', '').indexOf(w.valueM) > -1 ||
                  w.valueM.indexOf(item.originalText.replaceAll('\n', '')) > -1)) ||
              (item.showText &&
                item.showText.length > 1 &&
                w.targetX &&
                (item.showText.replaceAll('\n', '').indexOf(w.targetX) > -1 ||
                  w.targetX.indexOf(item.showText.replaceAll('\n', '')) > -1)) ||
              (item.showText &&
                item.showText.length > 1 &&
                w.targetM &&
                (item.showText.replaceAll('\n', '').indexOf(w.targetM) > -1 ||
                  w.targetM.indexOf(item.showText.replaceAll('\n', '')) > -1)) ||
              (item.showText &&
                item.showText.length > 1 &&
                w.target &&
                (item.showText.replaceAll('\n', '').indexOf(w.target) > -1 ||
                  w.target.indexOf(item.showText.replaceAll('\n', '')) > -1)) ||
              (item.originalText &&
                item.originalText.length > 1 &&
                w.value &&
                (item.originalText.replaceAll('\n', '').indexOf(w.value) > -1 ||
                  w.value.indexOf(item.originalText.replaceAll('\n', '')) > -1))
          ).length ||
          termBacks.filter(
            w =>
              (item.originalText &&
                item.originalText.length > 1 &&
                (item.originalText.replaceAll('\n', '').indexOf(w.value) > -1 ||
                  w.value.indexOf(item.originalText.replaceAll('\n', '')) > -1)) ||
              (item.showText &&
                item.showText.length > 1 &&
                (item.showText.replaceAll('\n', '').indexOf(w.target) > -1 ||
                  w.target.indexOf(item.showText.replaceAll('\n', '')) > -1))
          ).length ||
          termNotes.filter(
            w => w.target && (item.showText.indexOf(w.value) > -1 || item.showText.indexOf(w.target) > -1)
          ).length ||
          yahooOrKdxList.length ||
          blacklist.some(words => getBlackWordsTwo(item, words).length)) &&
          item
      "
    >
      <div
        v-if="(taskDetail.stageName == '翻译' || taskDetail.stageName == '校对') && changeAll"
        class="ocr-show-item-action-top"
      >
        <div class="ocr-show-item-action-title">【校对】对【翻译】软硬伤标记</div>
        <div class="ocr-show-item-action-radio">
          <span
            :style="taskDetail.stageName == '翻译' ? 'cursor: no-drop;' : ''"
            @click.stop="taskDetail.stageName == '翻译' ? () => {} : changeInjuryType(0)"
            :class="!selectErrorType ? 'error-span' : 'no-error-span'"
            >无<a-icon v-if="selectErrorTypes.some(s => s == 0)" type="check-circle" theme="filled"
          /></span>
          <span
            @click.stop="taskDetail.stageName == '翻译' ? () => {} : changeInjuryType(3)"
            :class="selectErrorType == 3 ? 'error-span' : 'no-error-span'"
            :style="
              taskDetail.stageName == '翻译'
                ? 'color: red; margin-left: 3px;cursor: no-drop;'
                : 'color: red; margin-left: 3px'
            "
            >错<a-icon v-if="selectErrorTypes.some(s => s == 3)" type="check-circle" theme="filled"
          /></span>
          <span
            @click.stop="taskDetail.stageName == '翻译' ? () => {} : changeInjuryType(2)"
            :class="selectErrorType == 2 ? 'error-span' : 'no-error-span'"
            style="color: red; margin-left: 3px"
            :style="
              taskDetail.stageName == '翻译'
                ? 'color: red; margin-left: 3px;cursor: no-drop;'
                : 'color: red; margin-left: 3px'
            "
            >硬<a-icon v-if="selectErrorTypes.some(s => s == 2)" type="check-circle" theme="filled"
          /></span>
          <span
            @click.stop="taskDetail.stageName == '翻译' ? () => {} : changeInjuryType(1)"
            :class="selectErrorType == 1 ? 'error-span' : 'no-error-span'"
            :style="
              taskDetail.stageName == '翻译'
                ? 'color: red; margin-left: 3px;cursor: no-drop;'
                : 'color: red; margin-left: 3px'
            "
            >软<a-icon v-if="selectErrorTypes.some(s => s == 1)" type="check-circle" theme="filled"
          /></span>
          <span
            @click.stop="taskDetail.stageName == '翻译' ? () => {} : changeInjuryType(4)"
            :class="selectErrorType == 4 ? 'error-span' : 'no-error-span'"
            :style="
              taskDetail.stageName == '翻译'
                ? 'color: orange; margin-left: 3px;cursor: no-drop;'
                : 'color: orange; margin-left: 3px'
            "
            >驳<a-icon v-if="selectErrorTypes.some(s => s == 4)" type="check-circle" theme="filled"
          /></span>
        </div>
        <div class="ocr-show-item-action-input" @mousemove="isDelete = true" @mouseout="isDelete = false">
          <div>
            <div v-if="!selectErrorType" style="padding-left:10px;">
              <div v-for="feedbackItem in topFeedbacks" :key="feedbackItem.id">
                <div class="revisition-content" style="display:flex;align-items: center;">
                  <div style="margin-right:5px;">
                    <img :src="feedbackItem.avatar" alt="" />
                  </div>
                  <div style="flex:1;">
                    <div style="height:18px;font-size:12px" class="revisition-content-error">
                      <span>{{ feedbackItem.username }}</span>
                      <span :class="'action-span'">
                        <a-dropdown
                          :trigger="['click']"
                          v-if="
                            (checkPermission('fk:edit') ||
                              checkPermission('fk:dele') ||
                              feedbackItem.userId == userInfo.id) &&
                              changeAll
                          "
                        >
                          <a-icon type="more" />
                          <a-menu slot="overlay">
                            <a-menu-item
                              v-if="feedbackItem.userId == userInfo.id || checkPermission('fk:edit')"
                              @mousemove="isDelete = true"
                              @mouseout="isDelete = false"
                              @click="editLocalContent(feedbackItem)"
                              key="0"
                            >
                              编辑
                            </a-menu-item>
                            <a-menu-item
                              v-if="feedbackItem.userId == userInfo.id || checkPermission('fk:dele')"
                              @mousemove="isDelete = true"
                              @mouseout="isDelete = false"
                              key="1"
                              @click="deletFeedback(feedbackItem, item)"
                            >
                              删除
                            </a-menu-item>
                          </a-menu>
                        </a-dropdown>
                      </span>
                    </div>
                    <div style="height:18px;font-size:12px;line-height:18px">
                      {{ feedbackItem.createTime }}
                    </div>
                  </div>
                </div>
                <div style="padding-left:25px;padding-top:5px;padding-bottom:5px;">
                  <span v-if="!feedbackItem.editble">{{ feedbackItem.feedback }}</span>
                  <a-textarea
                    v-else
                    :disabled="(!checkPermission('fk:edit') || !changeAll) && feedbackItem.userId != userInfo.id"
                    :auto-size="{ minRows: 1, maxRows: 10 }"
                    @click.stop
                    v-model="feedbackItem.feedback"
                    @focus="inputFocus"
                    @blur="inputBlur"
                    placeholder="ALT+回车换行，回车发送"
                    @keydown.enter="
                      e => {
                        feedbackItemEditEnter(e, feedbackItem, item)
                      }
                    "
                  />
                </div>
              </div>
            </div>
            <div
              style="padding-left:10px;"
              v-if="selectErrorType == 4 && topRejectComments && topRejectComments.length"
            >
              <div v-for="rejectItem in topRejectComments" :key="rejectItem.id + 'revision'">
                <div class="revisition-content" style="display:flex;align-items: center;color:rgba(0, 0, 0, 0.65);">
                  <div style="margin-right:5px;">
                    <img :src="rejectItem.avatar" alt="" />
                  </div>
                  <div style="flex:1;">
                    <div style="height:20px;font-size:12px" class="revisition-content-error">
                      <span>{{ rejectItem.username }}</span>
                      <span :class="'action-span'">
                        <a-dropdown
                          :trigger="['click']"
                          v-if="
                            (rejectItem.userId == userInfo.id ||
                              checkPermission('reject:edit') ||
                              checkPermission('reject:dele')) &&
                              changeAll
                          "
                        >
                          <a-icon type="more" />
                          <a-menu slot="overlay">
                            <a-menu-item
                              v-if="rejectItem.userId == userInfo.id || checkPermission('reject:edit')"
                              @click="editLocalContent(rejectItem)"
                              key="0"
                              @mousemove="isDelete = true"
                              @mouseout="isDelete = false"
                            >
                              编辑
                            </a-menu-item>
                            <a-menu-item
                              v-if="rejectItem.userId == userInfo.id || checkPermission('reject:dele')"
                              @mousemove="isDelete = true"
                              @mouseout="isDelete = false"
                              key="1"
                              @click="deletReject(rejectItem, item)"
                            >
                              删除
                            </a-menu-item>
                          </a-menu>
                        </a-dropdown>
                      </span>
                    </div>
                    <div style="height:18px;font-size:12px;line-height:18px">
                      {{ rejectItem.createTime }}
                    </div>
                  </div>
                </div>
                <div style="padding-left:25px;padding-top:5px;padding-bottom:5px;">
                  <span v-if="!rejectItem.editble" style="color:rgba(0, 0, 0, 0.65);">{{ rejectItem.comment }}</span>
                  <a-textarea
                    v-else-if="rejectItem.editble"
                    @click.stop
                    :disabled="(!checkPermission('reject:edit') || !changeAll) && rejectItem.userId != userInfo.id"
                    :auto-size="{ minRows: 1, maxRows: 10 }"
                    v-model="rejectItem.comment"
                    @focus="inputFocus"
                    @blur="inputBlur"
                    placeholder="ALT+回车换行，回车发送"
                    style="padding-left:0 !important"
                    @keydown.enter="
                      e => {
                        rejectEnter(e, rejectItem, item)
                      }
                    "
                  />
                </div>
              </div>
            </div>
            <div style="padding-left:12px;padding-bottom:10px;" v-if="selectErrorType > 0 && selectErrorType < 4">
              {{
                (selectErrorType == 1 && topInjuryType == 1) ||
                (selectErrorType == 2 && topInjuryType == 2) ||
                (selectErrorType == 3 && topInjuryType == 3)
                  ? topInjuryRemark
                  : ''
              }}
            </div>
          </div>
          <a-select
            v-if="selectErrorType != 4 && selectErrorType"
            v-model="errorValue"
            @mousemove="isDelete = true"
            @change="errorChange(item)"
            :disabled="taskDetail.stageName == '翻译'"
            show-search
            placeholder="请选择错误类型"
            style="width: 100%"
          >
            <a-select-option
              @mousemove="isDelete = true"
              v-for="errorItem in dictList"
              :key="errorItem.id"
              :value="errorItem.itemText"
            >
              {{ errorItem.itemText }}
            </a-select-option>
          </a-select>
          <a-textarea
            v-else
            @click.stop
            v-model="errorValue"
            @focus="inputFocus"
            @blur="inputBlur"
            :disabled="taskDetail.stageName == '翻译'"
            placeholder="ALT+回车换行，回车提交"
            @keydown.enter="
              e => {
                errorEnter(e, item, selectErrorType)
              }
            "
            :auto-size="{ minRows: 1, maxRows: 10 }"
          />
        </div>
      </div>
      <div
        v-if="(taskDetail.stageName == '终审' || taskDetail.stageName == '校对') && changeAll"
        class="ocr-show-item-action-top"
      >
        <div class="ocr-show-item-action-title">【终审】对【校对】软硬伤标记</div>
        <div class="ocr-show-item-action-radio">
          <span
            :style="taskDetail.stageName == '校对' ? 'cursor: no-drop;' : ''"
            @click.stop="taskDetail.stageName == '校对' ? () => {} : changeInjuryTypeBottom(0)"
            :class="!selectErrorTypeBottom ? 'error-span' : 'no-error-span'"
            >无<a-icon v-if="selectBottomErrorTypes.some(s => s == 0)" type="check-circle" theme="filled"
          /></span>
          <span
            @click.stop="taskDetail.stageName == '校对' ? () => {} : changeInjuryTypeBottom(3)"
            :class="selectErrorTypeBottom == 3 ? 'error-span' : 'no-error-span'"
            :style="
              taskDetail.stageName == '校对'
                ? 'color: red; margin-left: 3px;cursor: no-drop;'
                : 'color: red; margin-left: 3px'
            "
            >错<a-icon v-if="selectBottomErrorTypes.some(s => s == 3)" type="check-circle" theme="filled"
          /></span>
          <span
            @click.stop="taskDetail.stageName == '校对' ? () => {} : changeInjuryTypeBottom(2)"
            :class="selectErrorTypeBottom == 2 ? 'error-span' : 'no-error-span'"
            :style="
              taskDetail.stageName == '校对'
                ? 'color: red; margin-left: 3px;cursor: no-drop;'
                : 'color: red; margin-left: 3px'
            "
            >硬<a-icon v-if="selectBottomErrorTypes.some(s => s == 2)" type="check-circle" theme="filled"
          /></span>
          <span
            @click.stop="taskDetail.stageName == '校对' ? () => {} : changeInjuryTypeBottom(1)"
            :class="selectErrorTypeBottom == 1 ? 'error-span' : 'no-error-span'"
            :style="
              taskDetail.stageName == '校对'
                ? 'color: red; margin-left: 3px;cursor: no-drop;'
                : 'color: red; margin-left: 3px;'
            "
            >软<a-icon v-if="selectBottomErrorTypes.some(s => s == 1)" type="check-circle" theme="filled"
          /></span>
          <span
            @click.stop="taskDetail.stageName == '校对' ? () => {} : changeInjuryTypeBottom(4)"
            :class="selectErrorTypeBottom == 4 ? 'error-span' : 'no-error-span'"
            :style="
              taskDetail.stageName == '校对'
                ? 'color: orange; margin-left: 3px;cursor: no-drop;'
                : 'color: orange; margin-left: 3px'
            "
            >驳<a-icon v-if="selectBottomErrorTypes.some(s => s == 4)" type="check-circle" theme="filled"
          /></span>
        </div>
        <div class="ocr-show-item-action-input" @mousemove="isDelete = true" @mouseout="isDelete = false">
          <div>
            <div v-if="!selectErrorTypeBottom" style="padding-left:10px;">
              <div v-for="feedbackItem in bottomFeedbacks" :key="feedbackItem.id">
                <div class="revisition-content" style="display:flex;align-items: center;">
                  <div style="margin-right:5px;">
                    <img :src="feedbackItem.avatar" alt="" />
                  </div>
                  <div style="flex:1;">
                    <div style="height:18px;font-size:12px" class="revisition-content-error">
                      <span>{{ feedbackItem.username }}</span>
                      <span :class="'action-span'">
                        <a-dropdown
                          :trigger="['click']"
                          v-if="
                            (checkPermission('fk:edit') ||
                              checkPermission('fk:dele') ||
                              feedbackItem.userId == userInfo.id) &&
                              changeAll
                          "
                        >
                          <a-icon type="more" />
                          <a-menu slot="overlay">
                            <a-menu-item
                              v-if="feedbackItem.userId == userInfo.id || checkPermission('fk:edit')"
                              @mousemove="isDelete = true"
                              @mouseout="isDelete = false"
                              @click="editLocalContent(feedbackItem)"
                              key="0"
                            >
                              编辑
                            </a-menu-item>
                            <a-menu-item
                              v-if="feedbackItem.userId == userInfo.id || checkPermission('fk:dele')"
                              @mousemove="isDelete = true"
                              @mouseout="isDelete = false"
                              key="1"
                              @click="deletFeedback(feedbackItem, item)"
                            >
                              删除
                            </a-menu-item>
                          </a-menu>
                        </a-dropdown>
                      </span>
                    </div>
                    <div style="height:18px;font-size:12px;line-height:18px">
                      {{ feedbackItem.createTime }}
                    </div>
                  </div>
                </div>
                <div style="padding-left:25px;padding-top:5px;padding-bottom:5px;">
                  <span v-if="!feedbackItem.editble">{{ feedbackItem.feedback }}</span>
                  <a-textarea
                    v-else
                    :disabled="(!checkPermission('fk:edit') || !changeAll) && feedbackItem.userId != userInfo.id"
                    :auto-size="{ minRows: 1, maxRows: 10 }"
                    @click.stop
                    @focus="inputFocus"
                    @blur="inputBlur"
                    v-model="feedbackItem.feedback"
                    placeholder="ALT+回车换行，回车发送"
                    @keydown.enter="
                      e => {
                        feedbackItemEditEnter(e, feedbackItem, item)
                      }
                    "
                  />
                </div>
              </div>
            </div>
            <div
              style="padding-left:10px;"
              v-if="selectErrorTypeBottom == 4 && bottomRejectComments && bottomRejectComments.length"
            >
              <div v-for="rejectItem in bottomRejectComments" :key="rejectItem.id + 'revision'">
                <div class="revisition-content" style="display:flex;align-items: center;color:rgba(0, 0, 0, 0.65);">
                  <div style="margin-right:5px;">
                    <img :src="rejectItem.avatar" alt="" />
                  </div>
                  <div style="flex:1;">
                    <div style="height:20px;font-size:12px" class="revisition-content-error">
                      <span>{{ rejectItem.username }}</span>
                      <span :class="'action-span'">
                        <a-dropdown
                          :trigger="['click']"
                          v-if="
                            (rejectItem.userId == userInfo.id ||
                              checkPermission('reject:edit') ||
                              checkPermission('reject:dele')) &&
                              changeAll
                          "
                        >
                          <a-icon type="more" />
                          <a-menu slot="overlay">
                            <a-menu-item
                              v-if="rejectItem.userId == userInfo.id || checkPermission('reject:edit')"
                              @click="editLocalContent(rejectItem)"
                              key="0"
                              @mousemove="isDelete = true"
                              @mouseout="isDelete = false"
                            >
                              编辑
                            </a-menu-item>
                            <a-menu-item
                              v-if="rejectItem.userId == userInfo.id || checkPermission('reject:dele')"
                              @mousemove="isDelete = true"
                              @mouseout="isDelete = false"
                              key="1"
                              @click="deletReject(rejectItem, item)"
                            >
                              删除
                            </a-menu-item>
                          </a-menu>
                        </a-dropdown>
                      </span>
                    </div>
                    <div style="height:18px;font-size:12px;line-height:18px">
                      {{ rejectItem.createTime }}
                    </div>
                  </div>
                </div>
                <div style="padding-left:25px;padding-top:5px;padding-bottom:5px;">
                  <span v-if="!rejectItem.editble" style="color:rgba(0, 0, 0, 0.65);">{{ rejectItem.comment }}</span>
                  <a-textarea
                    v-else-if="rejectItem.editble"
                    @click.stop
                    :disabled="(!checkPermission('reject:edit') || !changeAll) && rejectItem.userId != userInfo.id"
                    :auto-size="{ minRows: 1, maxRows: 10 }"
                    v-model="rejectItem.comment"
                    @focus="inputFocus"
                    @blur="inputBlur"
                    placeholder="ALT+回车换行，回车发送"
                    style="padding-left:0 !important"
                    @keydown.enter="
                      e => {
                        rejectEnter(e, rejectItem, item)
                      }
                    "
                  />
                </div>
              </div>
            </div>
            <div
              style="padding-left:12px;padding-bottom:10px;"
              v-if="selectErrorTypeBottom > 0 && selectErrorTypeBottom < 4"
            >
              {{
                (selectErrorTypeBottom == 1 && bottomInjuryType == 1) ||
                (selectErrorTypeBottom == 2 && bottomInjuryType == 2) ||
                (selectErrorTypeBottom == 3 && bottomInjuryType == 3)
                  ? bottomInjuryRemark
                  : ''
              }}
            </div>
          </div>
          <a-select
            v-if="selectErrorTypeBottom != 4 && selectErrorTypeBottom"
            v-model="errorValueBottom"
            @mousemove="isDelete = true"
            :disabled="taskDetail.stageName == '校对'"
            @change="errorChangeBottom(item)"
            show-search
            placeholder="请选择错误类型"
            style="width: 100%"
          >
            <a-select-option
              @mousemove="isDelete = true"
              v-for="errorItem in dictList"
              :key="errorItem.id"
              :value="errorItem.itemText"
            >
              {{ errorItem.itemText }}
            </a-select-option>
          </a-select>
          <a-textarea
            v-else
            @click.stop
            @focus="inputFocus"
            @blur="inputBlur"
            v-model="errorValueBottom"
            :disabled="taskDetail.stageName == '校对'"
            placeholder="ALT+回车换行，回车提交"
            @keydown.enter="
              e => {
                errorEnterBottom(e, item, selectErrorTypeBottom)
              }
            "
            :auto-size="{ minRows: 1, maxRows: 10 }"
          />
        </div>
      </div>
      <div
        v-if="
          glossaryTags.filter(
            w =>
              (item.originalText &&
                (item.originalText.replaceAll('\n', '').indexOf(w.value) > -1 ||
                  w.value.indexOf(item.originalText.replaceAll('\n', '')) > -1)) ||
              (item.showText &&
                (item.showText.replaceAll('\n', '').indexOf(w.target) > -1 ||
                  w.target.indexOf(item.showText.replaceAll('\n', '')) > -1))
          ).length ||
            terms.filter(
              w =>
                (item.originalText &&
                  item.originalText.length > 1 &&
                  w.valueX &&
                  (item.originalText.replaceAll('\n', '').indexOf(w.valueX) > -1 ||
                    w.valueX.indexOf(item.originalText.replaceAll('\n', '')) > -1)) ||
                (item.originalText &&
                  item.originalText.length > 1 &&
                  w.valueM &&
                  (item.originalText.replaceAll('\n', '').indexOf(w.valueM) > -1 ||
                    w.valueM.indexOf(item.originalText.replaceAll('\n', '')) > -1)) ||
                (item.showText &&
                  item.showText.length > 1 &&
                  w.targetX &&
                  (item.showText.replaceAll('\n', '').indexOf(w.targetX) > -1 ||
                    w.targetX.indexOf(item.showText.replaceAll('\n', '')) > -1)) ||
                (item.showText &&
                  item.showText.length > 1 &&
                  w.targetM &&
                  (item.showText.replaceAll('\n', '').indexOf(w.targetM) > -1 ||
                    w.targetM.indexOf(item.showText.replaceAll('\n', '')) > -1)) ||
                (item.showText &&
                  item.showText.length > 1 &&
                  w.target &&
                  (item.showText.replaceAll('\n', '').indexOf(w.target) > -1 ||
                    w.target.indexOf(item.showText.replaceAll('\n', '')) > -1)) ||
                (item.originalText &&
                  item.originalText.length > 1 &&
                  w.value &&
                  (item.originalText.replaceAll('\n', '').indexOf(w.value) > -1 ||
                    w.value.indexOf(item.originalText.replaceAll('\n', '')) > -1))
            ).length ||
            termBacks.filter(
              w =>
                (item.originalText &&
                  item.originalText.length > 1 &&
                  (item.originalText.replaceAll('\n', '').indexOf(w.value) > -1 ||
                    w.value.indexOf(item.originalText.replaceAll('\n', '')) > -1)) ||
                (item.showText &&
                  item.showText.length > 1 &&
                  (item.showText.replaceAll('\n', '').indexOf(w.target) > -1 ||
                    w.target.indexOf(item.showText.replaceAll('\n', '')) > -1))
            ).length ||
            termNotes.filter(
              w =>
                w.target &&
                (item.showText.replaceAll('\n', '').indexOf(w.value) > -1 ||
                  item.showText.replaceAll('\n', '').indexOf(w.target) > -1)
            ).length ||
            yahooOrKdxList.length ||
            termsPhonetics.length ||
            blacklist.some(words => getBlackWordsTwo(item, words).length) ||
            blacklist.some(words => getBlackWords(item, words).length)
        "
        style="font-size:12px;"
        class="ocr-show-item-action-bottom"
      >
        <div v-if="termsPhonetics.length">
          <div style="font-weight: bold; padding-bottom: 8px">自动注音情况：</div>
          <div
            v-for="(word, wordIdx) in termsPhonetics"
            :key="wordIdx"
            style="padding-bottom: 8px;justify-content: space-between;display:flex"
          >
            <span>
              {{
                word.autoPhoneticTrigger == 0
                  ? '作品中每次：'
                  : word.autoPhoneticTrigger == 1
                  ? '作品中首次：'
                  : word.autoPhoneticTrigger == 2
                  ? '每话首次：'
                  : word.autoPhoneticTrigger == 3
                  ? '每页首次：'
                  : word.autoPhoneticTrigger == 4
                  ? '人员校正：'
                  : ''
              }}{{ `${word.processedName || '无译文名'}(${word.phoneticText})` }}
            </span>
            <span>
              <a v-if="changeAll" @click.stop="addPhonetic(item, word)">{{
                getIsAddPhoneticText(item, word) ? '撤销' : '注音'
              }}</a>
            </span>
          </div>
        </div>
        <div
          v-if="
            termNotes.filter(
              w =>
                w.target &&
                (item.showText.replaceAll('\n', '').indexOf(w.value) > -1 ||
                  item.showText.replaceAll('\n', '').indexOf(w.target) > -1)
            ).length
          "
        >
          <div style="font-weight: bold; padding-bottom: 8px">表记：</div>
          <div
            v-for="(word, wordIdx) in termNotes.filter(
              w =>
                w.target &&
                (item.showText.replaceAll('\n', '').indexOf(w.value) > -1 ||
                  item.showText.replaceAll('\n', '').indexOf(w.target) > -1)
            )"
            :key="wordIdx"
            style="padding-bottom: 8px"
          >
            <div style="justify-content: space-between;display:flex">
              <span>{{ word.value + '→' + word.target }}</span>
              <span>
                <a v-if="changeAll && getIsAddTableNote(item, word)" @click.stop="addTableNote(item, word)"
                  >替换<!-- {{ getIsAddTableNote(item, word) ? '撤销' : '替换' }} --></a
                >
              </span>
            </div>
          </div>
        </div>
        <div
          v-if="
            glossaryTags.filter(
              w =>
                (item.originalText &&
                  (item.originalText.replaceAll('\n', '').indexOf(w.value) > -1 ||
                    w.value.indexOf(item.originalText.replaceAll('\n', '')) > -1)) ||
                (item.showText &&
                  (item.showText.replaceAll('\n', '').indexOf(w.target) > -1 ||
                    w.target.indexOf(item.showText.replaceAll('\n', '')) > -1))
            ).length
          "
        >
          <div style="color: green; font-weight: bold; padding-bottom: 8px">专有词汇：</div>
          <div
            v-for="(word, wordIdx) in glossaryTags.filter(
              w =>
                (item.originalText &&
                  (item.originalText.replaceAll('\n', '').indexOf(w.value) > -1 ||
                    w.value.indexOf(item.originalText.replaceAll('\n', '')) > -1)) ||
                (item.showText &&
                  (item.showText.replaceAll('\n', '').indexOf(w.target) > -1 ||
                    w.target.indexOf(item.showText.replaceAll('\n', '')) > -1))
            )"
            style="padding-bottom: 8px"
            :key="wordIdx"
          >
            {{ word.value + '→' + word.target }}
          </div>
        </div>
        <div
          v-if="
            terms.filter(
              w =>
                (item.originalText &&
                  item.originalText.length > 1 &&
                  w.valueX &&
                  (item.originalText.replaceAll('\n', '').indexOf(w.valueX) > -1 ||
                    w.valueX.indexOf(item.originalText.replaceAll('\n', '')) > -1)) ||
                (item.originalText &&
                  item.originalText.length > 1 &&
                  w.valueM &&
                  (item.originalText.replaceAll('\n', '').indexOf(w.valueM) > -1 ||
                    w.valueM.indexOf(item.originalText.replaceAll('\n', '')) > -1)) ||
                (item.showText &&
                  item.showText.length > 1 &&
                  w.targetX &&
                  (item.showText.replaceAll('\n', '').indexOf(w.targetX) > -1 ||
                    w.targetX.indexOf(item.showText.replaceAll('\n', '')) > -1)) ||
                (item.showText &&
                  item.showText.length > 1 &&
                  w.targetM &&
                  (item.showText.replaceAll('\n', '').indexOf(w.targetM) > -1 ||
                    w.targetM.indexOf(item.showText.replaceAll('\n', '')) > -1)) ||
                (item.showText &&
                  item.showText.length > 1 &&
                  w.target &&
                  (item.showText.replaceAll('\n', '').indexOf(w.target) > -1 ||
                    w.target.indexOf(item.showText.replaceAll('\n', '')) > -1)) ||
                (item.originalText &&
                  item.originalText.length > 1 &&
                  w.value &&
                  (item.originalText.replaceAll('\n', '').indexOf(w.value) > -1 ||
                    w.value.indexOf(item.originalText.replaceAll('\n', '')) > -1))
            ).length
          "
        >
          <div style="color: green; font-weight: bold; padding-bottom: 8px">术语：</div>
          <div
            class="term-item"
            v-for="(word, wordIdx) in terms.filter(
              w =>
                (item.originalText &&
                  item.originalText.length > 1 &&
                  w.valueX &&
                  (item.originalText.replaceAll('\n', '').indexOf(w.valueX) > -1 ||
                    w.valueX.indexOf(item.originalText.replaceAll('\n', '')) > -1)) ||
                (item.originalText &&
                  item.originalText.length > 1 &&
                  w.valueM &&
                  (item.originalText.replaceAll('\n', '').indexOf(w.valueM) > -1 ||
                    w.valueM.indexOf(item.originalText.replaceAll('\n', '')) > -1)) ||
                (item.showText &&
                  item.showText.length > 1 &&
                  w.targetX &&
                  (item.showText.replaceAll('\n', '').indexOf(w.targetX) > -1 ||
                    w.targetX.indexOf(item.showText.replaceAll('\n', '')) > -1)) ||
                (item.showText &&
                  item.showText.length > 1 &&
                  w.targetM &&
                  (item.showText.replaceAll('\n', '').indexOf(w.targetM) > -1 ||
                    w.targetM.indexOf(item.showText.replaceAll('\n', '')) > -1)) ||
                (item.showText &&
                  item.showText.length > 1 &&
                  w.target &&
                  (item.showText.replaceAll('\n', '').indexOf(w.target) > -1 ||
                    w.target.indexOf(item.showText.replaceAll('\n', '')) > -1)) ||
                (item.originalText &&
                  item.originalText.length > 1 &&
                  w.value &&
                  (item.originalText.replaceAll('\n', '').indexOf(w.value) > -1 ||
                    w.value.indexOf(item.originalText.replaceAll('\n', '')) > -1))
            )"
            @click.stop="openTerm(word)"
            :key="wordIdx"
            style="padding-bottom: 8px"
          >
            {{ word.value + '→' + word.target }}
          </div>
        </div>
        <div
          v-if="
            termBacks.filter(
              w =>
                (item.originalText &&
                  item.originalText.length > 1 &&
                  (item.originalText.replaceAll('\n', '').indexOf(w.value) > -1 ||
                    w.value.indexOf(item.originalText.replaceAll('\n', '')) > -1)) ||
                (item.showText &&
                  item.showText.length > 1 &&
                  (item.showText.replaceAll('\n', '').indexOf(w.target) > -1 ||
                    w.target.indexOf(item.showText.replaceAll('\n', '')) > -1))
            ).length
          "
        >
          <div style="color: green; font-weight: bold; padding-bottom: 8px">背景册：</div>
          <div
            v-for="(word, wordIdx) in termBacks.filter(
              w =>
                (item.originalText &&
                  item.originalText.length > 1 &&
                  (item.originalText.replaceAll('\n', '').indexOf(w.value) > -1 ||
                    w.value.indexOf(item.originalText.replaceAll('\n', '')) > -1)) ||
                (item.showText &&
                  item.showText.length > 1 &&
                  (item.showText.replaceAll('\n', '').indexOf(w.target) > -1 ||
                    w.target.indexOf(item.showText.replaceAll('\n', '')) > -1))
            )"
            :key="wordIdx"
            style="padding-bottom: 8px"
          >
            {{ word.value + '→' + word.target }}
          </div>
        </div>
        <div v-if="blacklist.length">
          <template v-for="(words, wordsIdx) in blacklist">
            <div :key="wordsIdx" v-if="words.classId != 12 && getBlackWords(item, words)">
              <div style="color: red; font-weight: bold">{{ words.className }}：</div>
              <div>{{ getBlackWords(item, words) }}</div>
            </div>
            <div :key="wordsIdx" v-else-if="getBlackWordsTwo(item, words).length">
              <div style="color: red; font-weight: bold">{{ words.className }}：</div>
              <div v-for="(b, bIdx) in getBlackWordsTwo(item, words)" :key="bIdx">
                <div>{{ b.title }}</div>
                <div>{{ b.remark }}</div>
              </div>
            </div>
          </template>
        </div>
        <div v-if="yahooOrKdxList.length" style="padding-bottom:8px">
          <div style="color: red; font-weight: bold; padding-bottom: 8px; padding-top: 8px">
            第三方纠错：
          </div>
          <template v-for="(words, wordsIdx) in yahooOrKdxList">
            <div :key="wordsIdx" v-if="words.classId != 12 && getBlackWords(item, words)">
              <div>{{ words.className }}：{{ getBlackWords(item, words) }}</div>
            </div>
            <div
              :key="wordsIdx"
              v-else-if="getBlackWordsTwo(item, words).length"
              style="display: flex; padding-bottom: 8px"
            >
              <div>{{ words.className }}：</div>
              <div v-for="(b, bIdx) in getBlackWordsTwo(item, words)" :key="bIdx">
                <div>{{ b.title }}</div>
                <div>{{ b.remark }}</div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { v1postAction, getAction, v1DeleteAction } from '../../../api/manage'
import { checkPermission } from './../utils/hasPermission'
export default {
  props: {
    departName: {
      type: String,
      default: ''
    },
    selectTextAreaData: {
      type: Array,
      default: () => []
    },
    authList: {
      type: Array,
      default: () => []
    },
    changeAll: {
      type: Boolean,
      default: false
    },
    taskDetail: {
      type: Object,
      default: () => ({})
    },
    userInfo: {
      type: Object,
      default: () => ({})
    },
    terms: {
      type: Array,
      default: () => []
    },
    errorDictList: {
      type: Array,
      default: () => []
    },
    showImgLeftListPostions: {
      type: Array,
      default: () => []
    },
    pageListData: {
      type: Array,
      default: () => []
    },
    termsAll: {
      type: Array,
      default: () => []
    },
    glossaryTags: {
      type: Array,
      default: () => []
    },
    termBacks: {
      type: Array,
      default: () => []
    },
    blacklist: {
      type: Array,
      default: () => []
    },
    filtrationList: {
      type: Array,
      default: () => []
    },
    termNotes: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      position: {
        zIndex: 500,
        mouseX: 0,
        mouseY: 0,
        contentDrag: true
      },
      yahooOrKdxList: [],
      errorValue: undefined,
      errorValueBottom: undefined,
      selectErrorType: 0,
      selectErrorTypeBottom: 0,
      item: {
        originalText: '',
        showText: '',
        id: null
      },
      noDrag: false,
      termsPhonetics: [],
      isDelete: false,
      dictList: [],
      topFeedbacks: [],
      topRejectComments: [],
      topInjuryType: 0,
      topInjuryRemark: '',
      bottomFeedbacks: [],
      bottomRejectComments: [],
      bottomInjuryType: 0,
      bottomInjuryRemark: '',
      selectBottomErrorTypes: [],
      selectErrorTypes: []
    }
  },
  methods: {
    inputFocus() {
      this.$emit('changeCardInput', true)
    },
    inputBlur() {
      this.$emit('changeCardInput', false)
    },
    getCardStyle() {
      let style = ''
      if (this.showImgLeftListPostions.length) {
        let num = this.showImgLeftListPostions[0].width / this.pageListData[0].width - 0
        style = `transform:scale(${num.toFixed(2)});`
      }
      return style
    },
    checkPermission(permission) {
      return checkPermission(permission, this.authList)
    },
    async errorEnterBottom(e, item, selectErrorType) {
      //禁止回车的默认换行
      if (e.keyCode == 13 && e.altKey) {
        e.preventDefault()
        var textarea = e.target
        const position = this.getPositionForTextArea(textarea) //获取光标位置
        if (position.end - position.start == 0) {
          textarea.value = textarea.value.slice(0, position.start) + '\r\n' + textarea.value.slice(position.start)
          this.errorValueBottom = textarea.value
        } else {
          const strAry = textarea.value.split('')
          for (let i = position.start; i < position.end; i++) {
            strAry[i] = ''
          }
          textarea.value = strAry.join('')
          textarea.value = textarea.value.slice(0, position.start) + '\r\n' + textarea.value.slice(position.start)
          this.errorValueBottom = textarea.value
        }
        textarea.selectionStart = position.start + 1
        textarea.selectionEnd = position.start + 1
        //增加换行符
      } else if (e.keyCode == 13) {
        e.preventDefault()
        if (!selectErrorType) {
          let params = {
            feedback: this.errorValueBottom,
            textAreaId: item.id,
            stageName: this.taskDetail.stageName,
            replyId: null,
            userId: this.userInfo.id,
            username: this.userInfo.nickName,
            avatar: this.userInfo.userFile.avatar
          }
          const res = await v1postAction('/textarea/feedback/edit', params)
          if (res.code == 200) {
            this.errorValueBottom = ''
            this.selectErrorTypeBottom = 0
            this.$message.success('反馈成功')
            this.getRevisionList(item)
            this.$emit('getRevisionList')
          } else {
            this.$message.error(res.msg)
          }
          this.errorValueBottom = ''
          return
        }
        if (this.taskDetail.stageName != '校对' && this.taskDetail.stageName != '终审') {
          this.$message.error('当前阶段无法驳回')
          return
        }
        let params = {
          productionId: this.taskDetail.productionId,
          chapterId: this.taskDetail.chapterId,
          pageId: item.pageId,
          textAreaId: item.textAreaId,
          taskId: this.taskDetail.taskId,
          comment: this.errorValueBottom,
          rejectType: this.taskDetail.stageName == '校对' ? 1 : 2,
          userId: this.userInfo.id,
          username: this.userInfo.nickName,
          avatar: this.userInfo.userFile.avatar
        }
        const res = await v1postAction('/textarea/reject', params)
        if (res.code == 1) {
          this.errorValueBottom = ''
          /* this.selectErrorType = 0 */
          this.$message.success('驳回成功')
          this.getRevisionList(item)
          this.$emit('getRevisionList')
        } else {
          this.$message.error(res.msg)
        }
        /*         this.$emit('saveLocal', { type, item, value: this.errorValue })
        this.$forceUpdate() */
      }
    },
    async feedbackItemEditEnter(e, feedbackItem, item) {
      //禁止回车的默认换行
      if (e.keyCode == 13 && e.altKey) {
        e.preventDefault()
        var textarea = e.target
        const position = this.getPositionForTextArea(textarea) //获取光标位置
        if (position.end - position.start == 0) {
          textarea.value = textarea.value.slice(0, position.start) + '\r\n' + textarea.value.slice(position.start)
          feedbackItem.feedback = textarea.value
        } else {
          const strAry = textarea.value.split('')
          for (let i = position.start; i < position.end; i++) {
            strAry[i] = ''
          }
          textarea.value = strAry.join('')
          textarea.value = textarea.value.slice(0, position.start) + '\r\n' + textarea.value.slice(position.start)
          feedbackItem.feedback = textarea.value
        }
        textarea.selectionStart = position.start + 1
        textarea.selectionEnd = position.start + 1
        //增加换行符
      } else if (e.keyCode == 13) {
        e.preventDefault()
        //陈立说的谁都可以反馈
        /*         if (this.taskDetail.stageName != '校对' && this.taskDetail.stageName != '终审') {
          this.$message.error('当前阶段无法反馈')
          return
        } */
        if (!feedbackItem.feedback) {
          this.$message.error('内容不能为空')
          return
        }
        let params = {
          feedback: feedbackItem.feedback,
          textAreaId: item.textAreaId,
          stageName: this.taskDetail.stageName,
          id: feedbackItem.id,
          userId: this.userInfo.id,
          username: this.userInfo.nickName,
          avatar: this.userInfo.userFile.avatar
        }
        const res = await v1postAction('/textarea/feedback/edit', params)
        if (res.code == 200) {
          this.$message.success('编辑成功')
          this.getRevisionList(item)
          this.$emit('getRevisionList')
        } else {
          this.$message.error(res.msg)
        }
      }
    },
    async changeInjuryType(type) {
      this.selectErrorType = type
      this.dictList = []
      this.errorValue = undefined
      if (this.selectErrorType && this.selectErrorType != 4) {
        let code = !this.selectErrorType
          ? ''
          : this.selectErrorType == 1
          ? 'hy-rs'
          : this.selectErrorType == 2
          ? 'hy-ys'
          : this.selectErrorType == 3
          ? 'hy-cf'
          : ''
        if (this.departName.indexOf('日语部') > -1) {
          code = !this.selectErrorType
            ? ''
            : this.selectErrorType == 1
            ? 'ry-rs'
            : this.selectErrorType == 2
            ? 'ry-ys'
            : this.selectErrorType == 3
            ? 'ry-cf'
            : ''
          if (this.taskDetail.stageName == '校对' || this.taskDetail.stageName == '终审') {
            code = !this.selectErrorType
              ? ''
              : this.selectErrorType == 1
              ? 'jd_fy-zs_jd_rs'
              : this.selectErrorType == 2
              ? 'jd_fy-zs_jd_ys'
              : this.selectErrorType == 3
              ? 'jd_fy-zs_jd_cf'
              : ''
          }
        }
        let id = this.errorDictList.find(item => item.dictCode == code).id
        const res = await getAction('/sys/dictItem/list', { pageNo: 1, pageSize: 50, dictId: id })
        if (res.code == 200) {
          this.dictList = res.data.records
        }
      }
      if (!this.selectErrorType) {
        this.errorValue = undefined
      }
    },
    getIsAddTableNote(item, word) {
      let indexs = this.getAllTextIndex(item.showText, word.value)
      let isAdd = false
      if (indexs.length) {
        isAdd = true
      }
      /*       indexs.forEach(idx => {
        if (item.characterSentences[idx]) {
          if (
            item.characterSentences[idx].showText
          ) {
            
          }
        }
      }) */
      return isAdd
    },
    errorChange(item) {
      if (this.taskDetail.taskStatus != '可开始' && this.taskDetail.taskStatus != '驳回') return
      if (this.taskDetail.taskStatus == '驳回' && this.taskDetail.rejectTaskId) return
      this.$emit('changeError', { value: this.errorValue, type: this.selectErrorType, item })
      /* this.selectErrorType = 0 */
      this.errorValue = undefined
    },
    async rejectEnter(e, rejectItem, item) {
      //禁止回车的默认换行
      if (e.keyCode == 13 && e.altKey) {
        e.preventDefault()
        var textarea = e.target
        const position = this.getPositionForTextArea(textarea) //获取光标位置
        if (position.end - position.start == 0) {
          textarea.value = textarea.value.slice(0, position.start) + '\r\n' + textarea.value.slice(position.start)
          rejectItem.comment = textarea.value
        } else {
          const strAry = textarea.value.split('')
          for (let i = position.start; i < position.end; i++) {
            strAry[i] = ''
          }
          textarea.value = strAry.join('')
          textarea.value = textarea.value.slice(0, position.start) + '\r\n' + textarea.value.slice(position.start)
          rejectItem.comment = textarea.value
        }
        textarea.selectionStart = position.start + 1
        textarea.selectionEnd = position.start + 1
        //增加换行符
      } else if (e.keyCode == 13) {
        e.preventDefault()
        if (this.taskDetail.stageName != '校对' && this.taskDetail.stageName != '终审') {
          this.$message.error('当前阶段无法驳回')
          return
        }
        if (!rejectItem.comment) {
          this.$message.error('内容不能为空')
          return
        }
        let params = {
          comment: rejectItem.comment,
          stageName: this.taskDetail.stageName,
          textAreaRejectId: rejectItem.textAreaRejectId,
          userId: this.userInfo.id,
          username: this.userInfo.nickName,
          avatar: this.userInfo.userFile.avatar,
          id: rejectItem.id
        }
        const res = await v1postAction('/textarea/reject_msg/edit', params)
        if (res.code == 200) {
          this.$message.success('编辑成功')
          this.getRevisionList(item)
          this.$emit('getRevisionList')
        } else {
          this.$message.error(res.msg)
        }
      }
    },
    getAllTextIndex(str, searchStr) {
      let matches = str.matchAll(new RegExp(searchStr, 'g'))
      let indices = []

      for (let match of matches) {
        indices.push(match.index)
      }
      return indices
    },
    openTerm(word) {
      let record = this.termsAll.find(t => t.term.id == word.id)
      this.$emit('openEditTermModal', record)
      this.$emit('changeIsAddTerm', false)
    },
    errorChangeBottom(item) {
      if (this.taskDetail.taskStatus != '可开始' && this.taskDetail.taskStatus != '驳回') return
      if (this.taskDetail.taskStatus == '驳回' && this.taskDetail.rejectTaskId) return
      this.$emit('changeError', { value: this.errorValueBottom, type: this.selectErrorTypeBottom, item })
      /* this.selectErrorTypeBottom = 0 */
      this.errorValueBottom = undefined
    },
    async getRevisionList(textArea) {
      const res = await getAction('/original/textarea/' + textArea.id)
      if (res.code == 200) {
        this.topFeedbacks = res.data.proofreadFeedbacks || []
        this.topRejectComments = res.data.proofreadRejectComments || []
        this.topInjuryType = res.data.proofreadInjuryType || 0
        this.topInjuryRemark = res.data.proofreadInjuryRemark || ''
        this.bottomFeedbacks = res.data.finalCheckFeedbacks || []
        this.bottomRejectComments = res.data.finalCheckRejectComments || []
        this.bottomInjuryType = res.data.finalCheckInjuryType || 0
        this.bottomInjuryRemark = res.data.finalCheckInjuryRemark || ''
        this.topRejectComments?.forEach(item => (item.editble = false))
        this.topFeedbacks?.forEach(item => (item.editble = false))
        this.bottomRejectComments?.forEach(item => (item.editble = false))
        this.bottomFeedbacks?.forEach(item => (item.editble = false))
        this.selectErrorTypes = []
        this.selectBottomErrorTypes = []
        if (this.topFeedbacks.length) this.selectErrorTypes.push(0)
        if (this.topInjuryType) this.selectErrorTypes.push(this.topInjuryType)
        if (this.topRejectComments.length) this.selectErrorTypes.push(4)
        if (this.selectErrorTypes.length) {
          this.selectErrorType = this.selectErrorTypes[0]
        } else {
          this.selectErrorType = 0
        }
        if (this.bottomFeedbacks.length) this.selectBottomErrorTypes.push(0)
        if (this.bottomInjuryType) this.selectBottomErrorTypes.push(this.bottomInjuryType)
        if (this.bottomRejectComments.length) this.selectBottomErrorTypes.push(4)
        if (this.selectBottomErrorTypes.length) {
          this.selectErrorTypeBottom = this.selectBottomErrorTypes[0]
        } else {
          this.selectErrorTypeBottom = 0
        }
        this.$forceUpdate()
      }
    },
    deletFeedback(item, textArea) {
      let that = this
      this.$confirm({
        title: '确认删除?',
        okText: '确认',
        cancelText: '取消',
        zIndex: 9999,
        async onOk() {
          const res = await v1DeleteAction('/textarea/feedback/delete/' + item.id)
          if (res.code == 200) {
            that.$message.success('删除成功')
            that.$emit('getRevisionList')
            that.getRevisionList(textArea)
            that.$forceUpdate()
          } else {
            that.$message.error(res.msg)
          }
        },
        onCancel() {
          console.log('Cancel')
        }
      })
    },
    addPhonetic(item, word) {
      if (this.getIsAddPhonetic(item, word)) {
        let indexs = this.getAllTextIndex(item.showText, word.processedName)
        indexs.forEach(idx => {
          if (item.characterSentences[idx]) {
            item.characterSentences[idx].phonetic = null
          }
        })
      } else {
        if (!word.phoneticText) return
        let pushArr = []
        let textIds = []
        if (word.autoPhoneticTrigger > 0 && word.autoPhoneticTrigger < 4) {
          if (item.showText.indexOf(word.processedName) > -1) {
            let textLength = word.processedName.length
            let targetCharacterIds = []
            let text = item.characterSentences[item.showText.indexOf(word.processedName)]
            for (let i = 0; i < textLength; i++) {
              if (item.characterSentences[item.showText.indexOf(word.processedName) + i]) {
                targetCharacterIds.push(
                  item.characterSentences[item.showText.indexOf(word.processedName) + i].characterSentenceId
                )
              }
              if (i) {
                item.characterSentences[item.showText.indexOf(word.processedName) + i].phonetic = null
              }
            }
            text.phonetic = {
              fontSize: (text.fontSize - 1) / 2 + 1,
              letterSpacing: this.phoneticStyle.scaleX ? (this.phoneticStyle.fontSize / 10).toFixed(0) - 0 : 0,
              scaleY: this.phoneticStyle.scaleY,
              scaleX: this.phoneticStyle.scaleX,
              lineSpacing: this.phoneticStyle.lineSpacing || 1.15,
              textAlign: this.phoneticStyle.textAlign,
              fontColor: this.phoneticStyle.fontColor,
              phoneticText: word.phoneticText,
              targetCharacterIds: targetCharacterIds,
              letterSpacingOption: this.phoneticStyle.scaleX ? (this.phoneticStyle.fontSize / 10).toFixed(0) - 0 : 0
            }
            if (item.textLayout == 2) {
              if (word.phoneticText.length < 3) {
                text.phonetic.scaleY = 1
                text.phonetic.letterSpacing = 0
              } else if (word.phoneticText.length == 3) {
                text.phonetic.scaleY = 0.7
              } else {
                text.phonetic.scaleY = 0.6
                text.phonetic.letterSpacing = 0
              }
            } else {
              if (word.phoneticText.length < 3) {
                text.phonetic.scaleX = 1
                text.phonetic.letterSpacing = 0
              } else if (word.phoneticText.length == 3) {
                text.phonetic.scaleX = 0.7
              } else {
                text.phonetic.scaleX = 0.6
                text.phonetic.letterSpacing = 0
              }
            }
          }
        } else if (word.autoPhoneticTrigger == 0 || word.autoPhoneticTrigger == 4) {
          if (this.getAllTextIndex(item.showText, word.processedName).length) {
            this.getAllTextIndex(item.showText, word.processedName).forEach(idx => {
              if (pushArr.indexOf(idx) > -1) return
              let textLength = word.processedName.length
              let targetCharacterIds = []
              let text = item.characterSentences[idx]
              item.characterSentences.forEach(t => {
                if (t.phonetic && t.phonetic.targetCharacterIds.indexOf(text.characterSentenceId) > -1) {
                  t.phonetic = null
                }
              })
              if (text.characterSentenceId && textIds.indexOf(text.characterSentenceId) > -1) {
                text.phonetic = null
              }
              for (let i = 0; i < textLength; i++) {
                if (item.characterSentences[idx + i]) {
                  targetCharacterIds.push(item.characterSentences[idx + i].characterSentenceId)
                }
                if (i) {
                  if (item.characterSentences[idx + i]) {
                    item.characterSentences[idx + i].phonetic = null
                  }
                }
              }
              text.phonetic = {
                fontSize: (text.fontSize - 1) / 2 + 1,
                letterSpacing: this.phoneticStyle.scaleX ? (this.phoneticStyle.fontSize / 10).toFixed(0) - 0 : 0,
                scaleY: this.phoneticStyle.scaleY,
                scaleX: this.phoneticStyle.scaleX,
                lineSpacing: this.phoneticStyle.lineSpacing || 1.15,
                textAlign: this.phoneticStyle.textAlign,
                fontColor: this.phoneticStyle.fontColor,
                phoneticText: word.phoneticText,
                targetCharacterIds: targetCharacterIds,
                letterSpacingOption: this.phoneticStyle.scaleX ? (this.phoneticStyle.fontSize / 10).toFixed(0) - 0 : 0
              }
              if (item.textLayout == 2) {
                if (word.phoneticText.length < 3) {
                  text.phonetic.scaleY = 1
                  text.phonetic.letterSpacing = 0
                } else if (word.phoneticText.length == 3) {
                  text.phonetic.scaleY = 0.7
                } else {
                  text.phonetic.scaleY = 0.6
                  text.phonetic.letterSpacing = 0
                }
              } else {
                if (word.phoneticText.length < 3) {
                  text.phonetic.scaleX = 1
                  text.phonetic.letterSpacing = 0
                } else if (word.phoneticText.length == 3) {
                  text.phonetic.scaleX = 0.7
                } else {
                  text.phonetic.scaleX = 0.6
                  text.phonetic.letterSpacing = 0
                }
              }
              textIds = [...textIds, ...targetCharacterIds]
              pushArr.push(idx)
            })
          }
        }
        this.$forceUpdate()
      }
    },
    getIsAddPhoneticText(item, word) {
      let indexs = this.getAllTextIndex(item.showText, word.processedName)
      let isAdd = false
      indexs.forEach(idx => {
        if (item.characterSentences[idx]) {
          if (
            item.characterSentences[idx].phonetic &&
            item.characterSentences[idx].phonetic.phoneticText == word.phoneticText
          ) {
            isAdd = true
          }
        }
      })
      return isAdd
    },
    changeIscheckText(value) {
      this.$emit('changeIscheckText', value)
    },
    getIsAddPhonetic(item, word) {
      let indexs = this.getAllTextIndex(item.showText, word.processedName)
      let isAdd = false
      indexs.forEach(idx => {
        if (item.characterSentences[idx]) {
          if (
            item.characterSentences[idx].phonetic &&
            item.characterSentences[idx].phonetic.phoneticText == word.phoneticText
          ) {
            let targetCharacterIds = []
            for (let i = 0; i < word.processedName.length; i++) {
              if (item.characterSentences[idx + i]) {
                targetCharacterIds.push(item.characterSentences[idx + i].characterSentenceId)
              }
            }
            if (
              targetCharacterIds
                .filter((target, tIdx) => tIdx)
                .some(
                  targetId =>
                    item.characterSentences.find(findT => findT.characterSentenceId == targetId).phonetic &&
                    item.characterSentences.find(findT => findT.characterSentenceId == targetId).phonetic.phoneticText
                )
            ) {
              return
            }
            isAdd = true
          }
        }
      })
      return isAdd
    },
    getPositionForTextArea(dom) {
      //获取输入框内光标位置
      let CaretPos = {
        start: 0,
        end: 0
      }
      if (dom.selectionStart) {
        // Firefox support
        CaretPos.start = dom.selectionStart
      }
      if (dom.selectionEnd) {
        CaretPos.end = dom.selectionEnd
      }
      return CaretPos
    },
    async errorEnter(e, item, selectErrorType) {
      //禁止回车的默认换行
      if (e.keyCode == 13 && e.altKey) {
        e.preventDefault()
        var textarea = e.target
        const position = this.getPositionForTextArea(textarea) //获取光标位置
        if (position.end - position.start == 0) {
          textarea.value = textarea.value.slice(0, position.start) + '\r\n' + textarea.value.slice(position.start)
          this.errorValue = textarea.value
        } else {
          const strAry = textarea.value.split('')
          for (let i = position.start; i < position.end; i++) {
            strAry[i] = ''
          }
          textarea.value = strAry.join('')
          textarea.value = textarea.value.slice(0, position.start) + '\r\n' + textarea.value.slice(position.start)
          this.errorValue = textarea.value
        }
        textarea.selectionStart = position.start + 1
        textarea.selectionEnd = position.start + 1
        //增加换行符
      } else if (e.keyCode == 13) {
        e.preventDefault()
        if (!selectErrorType) {
          let params = {
            feedback: this.errorValue,
            textAreaId: item.id,
            stageName: this.taskDetail.stageName,
            replyId: null,
            userId: this.userInfo.id,
            username: this.userInfo.nickName,
            avatar: this.userInfo.userFile.avatar
          }
          const res = await v1postAction('/textarea/feedback/edit', params)
          if (res.code == 200) {
            this.errorValue = ''
            this.selectErrorType = 0
            this.$message.success('反馈成功')
            this.getRevisionList(item)
            this.$emit('getRevisionList')
          } else {
            this.$message.error(res.msg)
          }
          this.errorValue = ''
          return
        }
        if (this.taskDetail.stageName != '校对' && this.taskDetail.stageName != '终审') {
          this.$message.error('当前阶段无法驳回')
          return
        }
        let params = {
          productionId: this.taskDetail.productionId,
          chapterId: this.taskDetail.chapterId,
          pageId: item.pageId,
          textAreaId: item.textAreaId,
          taskId: this.taskDetail.taskId,
          comment: this.errorValue,
          rejectType: this.taskDetail.stageName == '校对' ? 1 : 2,
          userId: this.userInfo.id,
          username: this.userInfo.nickName,
          avatar: this.userInfo.userFile.avatar
        }
        const res = await v1postAction('/textarea/reject', params)
        if (res.code == 1) {
          this.errorValue = ''
          this.selectErrorType = 0
          this.$message.success('驳回成功')
          this.getRevisionList(item)
          this.$emit('getRevisionList')
        } else {
          this.$message.error(res.msg)
        }
        /*         this.$emit('saveLocal', { type, item, value: this.errorValue })
        this.$forceUpdate() */
      }
    },
    leftOut(e, item) {
      /*       if (item.textAreaRegion.selected) {
        e.target.style.cursor = 'default'
        this.isDelete = false
      } */
    },
    getBlackWordsTwo(item, words) {
      let blackWords = []
      if ((item.textAreaRegion && !item.textAreaRegion.selected) || !item.textAreaRegion) return blackWords
      words.words?.forEach(each => {
        if (
          each &&
          each.values &&
          each.values.some(value => item.showText.replaceAll('\n', '').indexOf(value) > -1) &&
          this.filtrationList.every(filtration => each.values.some(value => value.indexOf(filtration.value) == -1))
        ) {
          let emj = '→'
          if (words.className == '混淆词') emj = '&'
          blackWords.push({ title: each.values.join(emj), remark: each.remark })
        }
      })
      return blackWords
    },
    getBlackWords(item, words) {
      let t = ''
      if ((item.textAreaRegion && !item.textAreaRegion.selected) || !item.textAreaRegion) return t
      let blackWords = []
      words.words?.forEach(each => {
        if (
          each &&
          each.value &&
          item.showText.indexOf(each?.value) >= 0 &&
          this.filtrationList.every(filtration => each.value.indexOf(filtration.value) == -1)
        ) {
          if (each.target && each.value) {
            blackWords.push(each?.value + '→' + each.target)
          } else if (each.value && !each.target) {
            blackWords.push(each?.value)
          }
        }
      })
      if (blackWords.length) {
        blackWords = [...new Set(blackWords)]
        t = blackWords.join('，')
      }
      return t
    },
    topCenterMouseover() {
      this.$emit('topCenterMouseover')
    },
    topCenterMouseout() {
      /*  this.noDrag = true */
      this.$emit('topCenterMouseout')
    },
    mousedown(e) {
      if (this.noDrag || this.isDelete) return
      this.$emit('topCenterMouseover')
      this.position.contentDrag = true
      this.$emit('blackListChangeContentDarg', this.position)
    }
  }
}
</script>

<style lang="less" scoped>
.revisition-content {
  img {
    width: 20px;
    height: 20px;
    border-radius: 50%;
  }
}
.revisition-content-error {
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:hover {
    .action-span {
      display: inline-block !important;
    }
  }
  .action-span {
    transition: all 0.3s;
    cursor: pointer;
    &:hover {
      color: var(--theme-color) !important;
    }
  }
  .anticon-more {
    font-size: 16px;
  }
  .action-span-select {
    display: inline-block;
    &:hover {
      color: var(--theme-color) !important;
    }
  }
}
.term-item {
  cursor: pointer;
  &:hover {
    color: var(--theme-color);
  }
}
.drag-content-list {
  width: 300px;
  padding: 2px 15px 15px 15px;
  min-height: 100px;
  position: absolute;
  z-index: 500;
  border-radius: 5px;
}
.ocr-show-item-action {
  position: absolute;
  left: 0;
  width: 300px;
  z-index: 100;
  background: #fff;
  border-radius: 5px;
  box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.2);
  .ocr-show-item-action-top,
  .ocr-show-item-action-bottom {
    padding: 15px;
  }
  .ocr-show-item-action-top {
    padding-left: 2px;
    .ocr-show-item-action-title {
      font-size: 14px;
      padding-left: 10px;
      font-weight: bold;
      padding-bottom: 10px;
    }
    .ocr-show-item-action-radio {
      font-size: 14px;
      padding-left: 6px;
      .error-span {
        background-color: #f7f7f7;
        position: relative;
        .anticon-check-circle {
          position: absolute;
          top: -2px;
          right: -4px;
          font-size: 10px;
          color: var(--theme-color);
        }
      }
      .no-error-span {
        position: relative;
        .anticon-check-circle {
          position: absolute;
          top: -2px;
          right: -4px;
          font-size: 10px;
          color: var(--theme-color);
        }
      }
      > span {
        display: inline-block;
        width: 25px;
        height: 25px;
        text-align: center;
        cursor: pointer;
        &:hover {
          background-color: #f7f7f7;
        }
      }
    }
    .ocr-show-item-action-input {
      padding-top: 10px;
    }
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
}
.ant-input {
  border: none;
  &:focus {
    box-shadow: none;
  }
}
</style>
